import './App.css';
import { useEffect, useRef } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import { useVariableDataStore } from "./VariableDataStoreProvider";

import { Editor } from '@tinymce/tinymce-react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import fileDownload from 'js-file-download';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

function TemplateTitleEdit() {
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { topData, setTopData }           = useTopDataStore();
  const { variableData, setVariableData } = useVariableDataStore();  
  const editorRef = useRef(null);
  
  const goBack = () => {
    setTopData({
      ...topData,
      page: "templateTitleLayout",
    });  
  };
  
  const goToVariables = () => {};
  
  const saveChanges = () => {};
  
  const print = () => {};
  
  useEffect(() => {

    const compiled = templateData.lines.map((l) => {
      if (l.hide) {
        return <p align={l.align} key={l.id} dangerouslySetInnerHTML={{__html: l.html}}></p>;
      }
      else {
        const ih = `<strong><span data-label="${l.id}">${l.label}</span>: </strong>${l.html}`;
        return <p align={l.align} key={l.id} dangerouslySetInnerHTML={{__html: ih}}></p>;
      }
    });
    
    setTemplateData({
      ...templateData,
      compiled : compiled,
      compiledBlock : "",
      edit     : false,
      saving   : false,
    }); 
  }, []);
  
  const edit = () => {
    if (templateData.edit) {
      setTemplateData({
        ...templateData,
        saving : true,
      });
            
      // • Save complete (trimmed) HTML. 
      fetch(topData.endpoint + "/template", {
        method : "PUT",
        body   : JSON.stringify({
          email        : topData.userEmail,
          token        : topData.token,
          Key          : topData.AccessKeyId,
          Secret       : topData.SecretAccessKey,
          nonce        : topData.nonce,
          groupName    : topData.groupName,
          userId       : topData.userId,
          id           : templateData.template_id,
          version      : templateData.template_version,
          draft_id     : templateData.draft_id,
          titlePage    : editorRef.current.getContent(),
          frontMatter  : templateData.frontMatter,
          selected     : templateData.selected,
          name         : templateData.name,
          description  : templateData.description,
          status       : 'draft',
        })
      })
    
      setTemplateData({
        ...templateData,
        edit : false,
        compiledBlock : editorRef.current.getContent()
      });     
    }
    else {
      setTemplateData({
        ...templateData,
        edit : true,
        compiledBlock : document.getElementById('editableContent').innerHTML,
      });
    }
  };

  const s3_image_upload_handler = (blobInfo, progress) => {
    // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#images_upload_url
    return new Promise((resolve, reject) => {
      fetch(topData.endpoint + '/image', {
        method  : "POST",
        body    : JSON.stringify({
          email     : topData.userEmail,
          token     : topData.token,
          fileName  : blobInfo.filename(),
          Key       : topData.AccessKeyId,
          Secret    : topData.SecretAccessKey,
          nonce     : topData.nonce,
          groupName : topData.groupName,
        })
      })
      .then((response) => {
        if (200 === response.status) {
          response.json().then((data) => {
            let postData = new FormData()
            postData.append('key', data.response.fields.key);
            postData.append('AWSAccessKeyId', data.response.fields.AWSAccessKeyId);
            postData.append('policy', data.response.fields.policy);
            postData.append('signature', data.response.fields.signature);
            postData.append('file', blobInfo.blob(), blobInfo.filename());
            postData.append('Content-Type', blobInfo.blob().type);

            fetch(data.response.url, {
              method : "POST",
              body : postData
            })
            .then((resp) => {
              if (204 === resp.status) {
                const url = topData.endpoint + "/image?email=" + topData.userEmail + '&userId=' + topData.userId + "&filename=" + blobInfo.filename()  + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
                fetch(url, {
                  method : "GET",
                  cache : "no-cache",
                  headers : {
                    Authorization : topData.token,
                  }                  
                })
                .then((res) => {
                  if (200 === res.status) {
                    res.json().then((d) => {
                      resolve(d.url);
                    })
                  }
                  else {
                    res.json().then((data) => {
        
                      if (data.message) {
                        alert(data.message);
                      }
                      else {
                        alert('Your login has expired'); 
                      }
          
                      setTopData(initialState);          
                    })                    
                  }
                })
                .catch((error) => {
                  console.error('Error', error);           
                  reject({message: "Error getting file access.", remove: true});
                });                
              }
              else {
                reject({message: "Error uploading file.", remove: true});
              }
            })
            .catch((error) => {
              console.error('Error', error);           
              reject({message: "Error uploading file.", remove: true});
            });                
          })     
        }
        else {
          response.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
          
            setTopData(initialState);          
          })            
        }
      })
    }); 
  };  

  return (
    <Container className="p-3" id="contentContainer">
      <Row className="mb-3">
        <Col sm={12} style={{"marginBottom":"5px"}}>
          <h1 className="h2">Template {templateData.name}: Edit the title page.</h1>
          <p><span style={{"backgroundColor" : "#74FBEA"}}>
          Highlighted text</span> points out where your variables appear in the template. The 
          highlighting will not appear in the exported Word .docx document.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={goBack}
            >
              BACK: LAYOUT
            </Button>
          </div>              
        </Col>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={goToVariables}
            >
              MANAGE VARIABLES
            </Button>
          </div>              
        </Col>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={saveChanges}
            >
              SAVE
            </Button>
          </div>              
        </Col>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={print}
            >
              EXPORT TO PRINT
            </Button>
          </div>              
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={edit}
            >
              { templateData.edit ? 
                (templateData.saving ? 
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />          
                    : <>SAVE EDITS</>                
                ) : <>EDIT</> }
             </Button>
        </Col>
        <Col xs={10} id="editableContent">
          {
            templateData.edit ? 
              <Editor
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={templateData.compiledBlock}
                init={{
                  branding: false,
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist', 'lists', 'image', 'charmap',
                    'anchor', 'searchreplace', 'visualblocks', 
                    'insertdatetime', 'media', 'table', 'asclepia-group-variables'
                  ],
                  toolbar: 'undo redo | h1 h2 h3 | asclepia-group-variables |' +
                    'bold italic | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | table | image',
                  variables: variableData.variables,
                  images_upload_handler: s3_image_upload_handler,
                  file_picker_types: 'image',
                  image_advtab: true,
                  image_uploadtab: true,
                  images_file_types: 'jpeg,jpg,png,gif',
                  content_css: '/tiny.css',
                }}      
              />  
            : (templateData.compiledBlock ? <div dangerouslySetInnerHTML={{__html: templateData.compiledBlock}} /> : <>{templateData.compiled}</>)       
          }
        </Col>
      </Row>
      
    </Container>
  );
}

export default TemplateTitleEdit;