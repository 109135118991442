import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import { useVariableDataStore } from "./VariableDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TemplateTitleEdit from "./TemplateTitleEdit";
import TemplateSetup from "./TemplateSetup";
import TemplateText from "./TemplateText";
import TemplateSaveChoices from "./TemplateSaveChoices";
import Variables from "./Variables";
import TemplateTitlePage from "./TemplateTitlePage";
import TemplateTitleLayout from "./TemplateTitleLayout";

function OutletSwitch() {
    const { topData }           = useTopDataStore();
    
    switch (topData.page) {         
        case "templateTitlePage":
          return (<TemplateTitlePage />);
        case "templateTitleLayout":
          return (<TemplateTitleLayout />);
        case "templateTitleEdit":
          return (<TemplateTitleEdit />);
        case "templateSetup":
          return (<TemplateSetup />);
        case "templateText":
          return (<TemplateText />);
        case "templateSaveChoices":
          return (<TemplateSaveChoices />);
        case "variables":
            return(<Variables />);


        default:
          return (<TemplateText />);
      }

}

function OutletSidebar() {
    const { topData, setTopData }           = useTopDataStore();

  // AIMEE: OR
  // DEVIN:   We need to add for all goTo....
  // Logic to ensure that you are saving work in the current page when navigating away.
  // <TemplateText> needs to call const saveDraft [()] or trigger  alert [[or call goToVariables?]]
  // TemplateSaveChoices doesn't need to call anything 
  // TemplateSetup needs to call goToText(e) from inside function Sections()
  // Variables doesn't seem to need to call anything (goBack only changes page) -- but should it?
  // SO -- how to call a const inside a function from outside // (but are they local?)
  // or how to trigger a useEffect to update when the page changes. 

  // Alternatively -- can we think about coding something from inside that triggers 
  // a const call for each page when trying to navigate away from this? [I'll research]

  const goToOutline = (event) => {
    event.preventDefault(); 
    console.log("here1");
    setTopData({ ...topData, page: 'templateSetup'});
    console.log("here2");
  }

  const goToVariables = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: 'variables'});
  }

  const goToText = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: 'templateText'});

  }

  const goToSave = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: 'templateSaveChoices'});

  }

  const goToTP = (event) => {
    event.preventDefault();
    // setTopData({ ...topData, page: 'templateTitlePage'});

  }

  return(
    <>
        <ul className="nav flex-column">
            <li className="nav-item intNav1" key="tempOutline">
                <a href="/outline" className={(topData.page === 'templateSetup') ? "selected" : ""}
                  onClick={goToOutline}>
                     Outline
                </a>
            </li>
            <li className="nav-item intNav1" key="tempText">
                <a href="/outline"  className={(topData.page === 'templateText') ? "selected" : ""}
                  onClick={goToText}>
                    Document Text
                </a>
            </li>
            <li className="nav-item intNav1" key="tempTP">
                <a href="/outline"  className={(topData.page === 'templateTitlePage') ? "selected" : ""}
                  onClick={goToTP}>
                    Standard Pages
                </a>
            </li>
            <li className="nav-item intNav1" key="tempVariables">
                <a href="/outline"  className={(topData.page === 'variables') ? "selected" : ""}
                  onClick={goToVariables}>
                    Manage Variables
                </a>
            </li>
            <li className="nav-item intNav1" key="tempSave">
                <a href="/outline"  className={(topData.page === 'templateSaveChoices') ? "selected" : ""}
                  onClick={goToSave}>
                    Save
                </a>
            </li>
        </ul>
    </>
  );
}

function TemplateOutletID() {
  const { templateData }                  = useTemplateDataStore();
  const { variableData, setVariableData } = useVariableDataStore();
  const { topData, setTopData }           = useTopDataStore();

  let dataFetching = false;
  useEffect(() => {
    if (!dataFetching && (!variableData.variables || !Object.keys(variableData.variables).length)) {
      dataFetching = true;
      
      if (templateData.template_id) {
        const url = topData.endpoint + "/variable?email=" + topData.userEmail + "&template_id=" + templateData.template_id + "&template_version=" + templateData.template_version + "&draft_id=" + templateData.draft_id + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
        fetch(url, {
          method : "GET",
          cache : "no-cache",
          headers : {
            Authorization : topData.token,
          }      
        })
        .then((res) => {
          if (200 === res.status) {
            res.json().then((returned) => {
              const vars = (returned.data.length) ? returned.data : templateData.default_vars.reduce((prev, curr) => {
                return { ...prev, [curr.id] : curr, }
              }, {});
              
              const keys   = Object.keys(vars);
              const active = keys.reduce((o, k) => {
                if (!vars[k].howDelete) {
                  o[k] = vars[k];
                }
                return o;
              }, {});
            
              setVariableData({
                ...variableData,
                variables : vars,
                activeVariables : active,
              });  
              dataFetching = false;  
            });
          }
          else {
            res.json().then((data) => {
        
              if (data.message) {
                alert(data.message);
              }
              else {
                alert('Your login has expired'); 
              }
          
              setTopData(initialState);          
            });      
          }
        })
        .catch((err) => {
          console.error(err);
          alert('error');
        });            
      }
      else {
        setVariableData({
          ...variableData,
          variables : templateData.default_vars.reduce((prev, curr) => {
            return { ...prev, [curr.id] : curr, }
          }, {}),
          activeVariables : templateData.default_vars.reduce((prev, curr) => {
            if (!curr.howDelete) {
              prev[curr.id] = curr;
            }
            return prev;
          }, {})
        });   
        dataFetching = false; 
      }
    }  
    else {
      if (variableData.deleting && "confirmed" === variableData.deleting) {
        setVariableData({ ...variableData, variables : null, howDelete : {}, deleting: "", });
      }
    }
  }, [variableData.deleting]);

  return (
        <Container className="mainPage">
            <Row>
                <h2 className="pageTitle">
                    {templateData.name || "New Template"}
                </h2>
            </Row>
            <Row> 
                <Col xs={3} className="sectionSidebar">
                    <OutletSidebar />
                </Col>
                <Col>
                    <OutletSwitch />            
                </Col>
            </Row>
        </Container>

    );
}

export default TemplateOutletID;


/*

            <Row className="pageSubtitle">
                <Col xs={3}>
                    <h3 className="pageLevel1Head">
                      Template Editor
                    </h3>
                </Col>
                <Col xs={8}>
                    <p className="pageInstructions">
                        
                    </p>
                </Col>
            </Row> 

            */