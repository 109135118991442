import './App.css';
import { useEffect, useState } from "react"; // I don't think we need "useState" here? 
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useNotificationsDataStore } from "./NotificationsDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { formatDistance, fromUnixTime } from 'date-fns'

function AllTableRows() {
  const { topData } = useTopDataStore();
    
  const barColors    = ['#CD6124', '#454E62', '#93A1A3'];
  const nextBarColor = (i) => {
    return barColors[(i % barColors.length)];
  };
    
  if (topData.existing) {
    return topData.existing.map((doc, index) => {
      const docColor = nextBarColor(index);
              
      if (doc.documentName) { 
        return(
          <tr key={doc.documentId}>
          <td className="dashRowLabel">
            {doc.documentName}
          </td>
          <td className="dashBar" 
            style={{backgroundColor: docColor}}>&nbsp;
          </td>
          {
            ['inputs', 'draft', 'review', 'finalization', 'version'].includes(doc.status) 
            ? <td className="dashBar" style={{backgroundColor: docColor}}>
                &nbsp;
              </td>
            : <td>&nbsp;</td>
          }
          {
           ['draft', 'review', 'finalization', 'version'].includes(doc.status) 
           ? <td className="dashBar" style={{backgroundColor: docColor}}>
              &nbsp;
            </td>
           : <td>&nbsp;</td>
          }
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        ); 
      }
      else {
        return "";
      } 
    });
  }
  else {
    return [];
  }
}

function Notifications() {
  const { notificationData } = useNotificationsDataStore();

  if (notificationData.notifications && notificationData.notifications.length) {
    return notificationData.notifications.map((note) => {
      if (0 === note.timestamp) {
        return (<></>);
      }
      else {
        return (
          <Row key={note.id} className="notifyEntry"> 
            <Col>
              <p className="notifyText">
                <span className="notifyName">
                  {note.user_name}
                </span>
                <br/>
                {note.text}
              </p>            
              <p className="text-secondary notifTime">
                <em>
                  {formatDistance(fromUnixTime(note.timestamp/1000), fromUnixTime(notificationData.referenceDate/1000))} ago
                </em>
              </p>
            </Col>
          </Row>
        );      
      }
    });
  }
  else {
    return "";
  }
}

function Dashboard() {
  const { topData, setTopData } = useTopDataStore();
  const { notificationData, setNotificationData } = useNotificationsDataStore();
  
  const getNotifications = () => {
    const param = [topData.userEmail, topData.userId, topData.AccessKeyId, topData.SecretAccessKey, topData.nonce, topData.groupName];
    const url = topData.endpoint + "/notifications?p=" + param.join(',');
    fetch(url, {
      method : "GET",
      cache : "no-cache",
      headers : {
        Authorization : topData.token,
      }      
    })
    .then((res) => {
      if (401 === res.status) { 
        res.json().then((data) => {

          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }

          setTopData(initialState);          
        })
      }
      else if (200 === res.status) {
        res.json().then((data) => {
          setNotificationData({
            ...notificationData,
            notifications : data['notifications'], 
            referenceDate : data['now'],           
          });
         });
      }
    })
    .catch((err) => {
      console.log(err);
    });
  
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    
    getNotifications();
    
    const interval = setInterval(() => {
      getNotifications();
    }, 300000);
        
    return () => clearInterval(interval);
  }, []);

  const allRead = (event) => {
    event.preventDefault();
    
    setNotificationData({
      ...notificationData,
      clearingNotifications : true,
    });
        
    fetch(topData.endpoint + '/notifications', {
      method : "DELETE",
      body   : JSON.stringify({
        userId    : topData.userId,
        email     : topData.userEmail,
        token     : topData.token,        
        Key       : topData.AccessKeyId,
        Secret    : topData.SecretAccessKey,
        nonce     : topData.nonce,
        groupName : topData.groupName,
      })
    })
    .then((res) => {
      if (401 === res.status) { 
        res.json().then((data) => {

          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }

          setTopData(initialState);          
        })
      }
      else if (200 === res.status) {
        setNotificationData({
          ...notificationData,
          clearingNotifications : false, 
          notifications : [],
        });
      }
    })
    .catch((err) => {
      console.log(err);
    })
  };
  
  return (
    <Container className="mainPage">
      <Row className="mainPageRow">
        <Col xs="6">
          <p className="welcomeText">
            Welcome to Kinetika by Asclepia! This dashboard will get you oriented to your document, 
            show team notifications, and assist with project management 
            by providing the team a quick view of the phase of document 
            creation.
          </p>
        </Col>
        <Col xs="6">
          <Card  className="notifyBody">
            <Card.Header className="notifyHead">
              <span className="notifyLabel">Notifications</span>
              <div className="float-end notifyRead">
                {
                  notificationData.clearingNotifications ? 
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  :
                  <a href="/clear" 
                    onClick={allRead}>Mark all as read</a>
                }
              </div>
            </Card.Header>
            <Card.Text as="div" className="notifBody">
              <Container>
                <Notifications />
              </Container>
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <Row className="dashRow">
        <Col xs="10" className="dashboardTable">
          <Table className="intTable">
            <thead>
             <tr>
                <th className="dashHeadLabel">
                 Active Documents
                </th>
                <th className="dashHeadLabel">
                  Creation
                </th>
                <th className="dashHeadLabel">
                  Inputs
                </th>
                <th className="dashHeadLabel">
                  Draft
                </th>
                <th className="dashHeadLabel">
                  Review
                </th>
                <th className="dashHeadLabel">
                  Finalization
                </th>
                <th className="dashHeadLabel">
                  Version
                </th>
              </tr>
            </thead>
            <tbody>
              <AllTableRows />
            </tbody>
          </Table> 
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;