import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { Col } from 'react-bootstrap';

function File() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
    </svg>
  );
}

function OutletSidebar() {
  return(
  <>
      <ul className="nav flex-column">
          <li className="nav-item intNav1" key="BaseTemplate">
              <span className="selected">
                   Select a Base Template
              </span>
          </li>
          <li className="nav-item intNav1" key="ProjectName">
              <span className="">
                   Project Name
              </span>
          </li>
          <li className="nav-item intNav1" key="ManageMembers">
              <span className="">
                   Manage Members
              </span>
          </li>
      </ul>
  </>
  );
}

function TemplateList() {
  const { topData, setTopData }           = useTopDataStore();
  const { templateData } = useTemplateDataStore();
  
  const gotoInput = (event, id, version) => {
    event.preventDefault();

    const [sectionData, linesData] = templateData.templates.reduce((prev, curr) => {
      if (curr.id === id) {
        if (curr.lines) {
          return [curr.sections, curr.lines];
        }
        else {
          return [curr.sections, []];
        }
      }
      else {
        return prev;
      }
    }, []);
  
    setTopData({
      ...topData,
      template_id      : id,
      template_version : version,
      sections         : sectionData,
      lines            : linesData,
      document_id      : 0,
      page             : 'studyInformation',
    });
  };
  
  return templateData.templates.map((t) => {
    return (
      <div key={t.id} className="docListItem">
        <a href="#id" onClick={(e) => gotoInput(e, t.id, t.version)}>
          {t.name} <br/><span className="docListItemDetail">{t.description}</span>
        </a>
      </div>);
  });   
}

function ChooseTemplateMain() {
  const { topData, setTopData }           = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();

  let dataFetching = false;
  useEffect(() => {
    if (!dataFetching) {
      dataFetching = true;
      
      window.scrollTo(0, 0);   
      setTemplateData({
        ...templateData,
        footer : false,
      });

      const url = topData.endpoint + "/template?email=" + topData.userEmail + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
      fetch(url, {
        method : "GET",
        cache : "no-cache",
        headers : {
          Authorization : topData.token,
        }      
      })
      .then((res) => {
        if (200 === res.status) {
          res.json().then((data) => {
            setTemplateData({
              ...templateData,
              templates : data.templates,
              drafts    : data.drafts,
            });
          })
        }
        else {
          res.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
          
            setTopData(initialState);          
          })      
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });      
    }
  }, []);
  
  return (
    <Container className="p-3 mainPage">
      <Row>
        {
          templateData.templates ? 
            <>
              <TemplateList />
            </>
          : <><Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            /><br/><br />
              <p className="loadWarning">
                Template list is loading from the database. 
                If this takes longer than anticipated, contact the database administrator.
              </p>
            </>                
        }
      </Row>
    </Container>
  );
}

function ChooseTemplate() {
  const { templateData } = useTemplateDataStore();

// DEVIN: Help with what Datafetching to add here?

return (
      <Container className="mainPage">
          <Row>
              <h2 className="pageTitle">
                  { "Document Creator"}
              </h2>
          </Row>
          <Row> 
              <Col xs={3} className="sectionSidebar">
                  <OutletSidebar />
              </Col>
              <Col>
                  <ChooseTemplateMain />            
              </Col>
          </Row>
      </Container>

  );
}

export default ChooseTemplate;