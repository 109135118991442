import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import SidebarDataStoreProvider from "./SidebarDataStoreProvider";
import TemplateOutletID from './TemplateOutletID';
import VariableDataStoreProvider from './VariableDataStoreProvider';
import NotificationsDataStoreProvider from "./NotificationsDataStoreProvider";
import DocumentDataStoreProvider from "./DocumentDataStoreProvider";
import TemplateDataStore from "./TemplateDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ChooseTemplate from "./ChooseTemplate";
import Share from "./Share";
import OnBoarding from "./OnBoarding";
import Profile from "./Profile";
import Dashboard from "./Dashboard";
import ManageDocuments from "./ManageDocuments";
import ManageSpecificDocument from "./ManageSpecificDocument";
import HorizNav from "./HorizNav";
import DocumentList from "./DocumentList";
import DocumentOutletId from './DocumentOutletId';
import TemplatesList from "./TemplatesList";

function Content() {
  const { topData } = useTopDataStore();

  switch (topData.page) {
    case "documentPageDisambiguate":
    case "edit":
    case "synopsis":
    case "studyInformation":
    case "objectives":
    case 'studyDesign':
    case 'patientPopulation':
    case '_variables':
      return (<DocumentDataStoreProvider><DocumentOutletId /></DocumentDataStoreProvider>);
      
    case "share":
      return (<DocumentDataStoreProvider><Share /></DocumentDataStoreProvider>);
    case "newuser":
      return <OnBoarding />;
    case "profile":
      return <Profile />;
    case "welcome":
      return (
        <NotificationsDataStoreProvider>
          <Dashboard />
        </NotificationsDataStoreProvider>
      );
    case "manageDocuments":
      return (<DocumentDataStoreProvider><ManageDocuments /></DocumentDataStoreProvider>);
    case "manageSpecificDocument":
      return (<DocumentDataStoreProvider><ManageSpecificDocument /></DocumentDataStoreProvider>);
    case "templates":
      return (<TemplateDataStore><TemplatesList /></TemplateDataStore>);
    case "chooseTemplate":
      return (<TemplateDataStore><ChooseTemplate /></TemplateDataStore>);
    case "documentList":
      return (<DocumentList />);
    case "templateSetup":
    case "templateText":
    case "templateSaveChoices":
    case "variables":
    case "templateTitleLayout":
    case "templateTitleEdit":
      return (
        <TemplateDataStore>
          <VariableDataStoreProvider>
            <TemplateOutletID />
          </VariableDataStoreProvider>
        </TemplateDataStore>
      );  
    default:
      return (
        <NotificationsDataStoreProvider>
          <Dashboard />
        </NotificationsDataStoreProvider>
      );
  }
}
 
function Wrapper() {
  const { topData, setTopData } = useTopDataStore();
  
  let dupCheck = true
  useEffect(() => {
    if (dupCheck) {
      dupCheck = false;
  
      // Terminal error: Line 122:18:  Assignments to the 'dupCheck' variable from inside React Hook useEffect will be lost after each render. To preserve the value over time, store it in a useRef Hook and keep the mutable value in the '.current' property. Otherwise, you can move this variable directly inside useEffect  react-hooks/exhaustive-deps
      let interval = null;
      interval = setInterval(() => {
        if (topData.AccessKeyId) {
          const url = topData.endpoint + "/status?email=" + topData.userEmail + '&userId=' + topData.userId + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
          fetch(url, {
            method : "GET",
            cache : "no-cache",
            headers : {
              Authorization : topData.token,
            }      
          })
          .then((res) => {
            if (401 === res.status) { 
              res.json().then((data) => {

                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }
                
                clearInterval(interval);
                setTopData(initialState);          
              })
            }
            else if (200 === res.status) {
              res.json().then((data) => {
                if ("offline" === data.status) {
                  if ("offline" !== topData.page) {
                    clearInterval(interval);
                    setTopData({
                      ...topData,
                      page        : "offline",
                    });      
                  }
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });      
        } 
      }, 120000);
    
      return () => {
        clearInterval(interval);
      }    
    }
  }, [topData.page]);  
  
  return (
    <Container fluid>
      <Row id="sidebarRow">
        <DocumentDataStoreProvider>
        <TemplateDataStore>
        <SidebarDataStoreProvider>
          <HorizNav />
        </SidebarDataStoreProvider>
        </TemplateDataStore>
        </DocumentDataStoreProvider>
      </Row>
      <Row className="contentBgrd">
        <Content />
      </Row>
    </Container>
  );
  
}

export default Wrapper;
