import { createContext, useState, useContext } from "react";

const NotificationsDataStore = createContext();
export const useNotificationsDataStore = () => useContext(NotificationsDataStore);

export default function NotificationsDataStoreProvider({children}) {
  const [notificationData, setNotificationData] = useState({notifications: []});
  
  return (
    <NotificationsDataStore.Provider value={{ notificationData, setNotificationData }}>
      {children}
    </NotificationsDataStore.Provider>
  );
}