import { createContext, useState, useContext } from "react";

const SidebarDataStore = createContext();
export const useSidebarDataStore = () => useContext(SidebarDataStore);

export default function SidebarDataStoreProvider({children}) {
  const [sidebarData, setSidebarData] = useState({});
  
  return (
    <SidebarDataStore.Provider value={{ sidebarData, setSidebarData }}>
      {children}
    </SidebarDataStore.Provider>
  );
}