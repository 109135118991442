import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useDocumentDataStore } from "./DocumentDataStoreProvider";
import DocumentDataStoreProvider from './DocumentDataStoreProvider';
import SynopsisDataStoreProvider from './SynopsisDataStoreProvider';
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import { useVariableDataStore } from "./VariableDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LoadingDoc from "./LoadingDoc";
import EditDoc from './EditDoc';
import EditSynopsis from "./EditSynopsis";
import DataForm from "./DataForm";

function OutletSwitch() {
  const { topData } = useTopDataStore();
  
  switch (topData.page) {    
      case "documentPageDisambiguate":
        return (<LoadingDoc />);
      case "edit":
        return (<EditDoc />);
      case "synopsis":
        return (
          <SynopsisDataStoreProvider>
            <EditSynopsis />
          </SynopsisDataStoreProvider>
      );     
      case "studyInformation":
      case "objectives":
      case "studyDesign":
      case "patientPopulation":
      case "_variables":
      case "templateTitleLayout":
      case "templateTitleEdit":
        return(<DataForm />);
      default:
        return (<EditDoc />);
    }
}

function OutletSidebar() {
  const { topData, setTopData } = useTopDataStore();

  const goToStudyInformation = (event) => {
    event.preventDefault(); 
    setTopData({ ...topData, page: 'studyInformation'});
  }

  const goToObjectives = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: 'objectives'});
  }

  const goToStudyDesign = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: 'studyDesign'});
  }

  const goToPatient = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: 'patientPopulation'});
  }

  const goToVariables = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: '_variables'});
  }

  const goToLoading = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: 'documentPageDisambiguate'});
  }

  const goToEdit = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: 'edit'});
  }

  const goToSynopsis = (event) => {
    event.preventDefault();
    setTopData({ ...topData, page: 'synopsis'});
  }

  return(
    <>
        <ul className="nav flex-column">
            <li className="nav-item intNav1" key="dataEntry">
                <a href="/outline" 
                  className={(topData.page === 'studyInformation') || (topData.page === 'objectives') || (topData.page === 'studyDesign')
                    || (topData.page === 'goToPatient') || (topData.page === '_variables') ? "selected" : ""}
                  onClick={goToStudyInformation}>
                    Data Entry
                </a>
            </li>
            <li className="nav-item intNav1 navVar" key="studyInformation">
                <a href="/outline" className={(topData.page === 'studyInformation') ? "selected2" : ""}
                  onClick={goToStudyInformation}>
                    Study Information
                </a>
            </li>
            <li className="nav-item intNav1 navVar" key="objectives">
                <a href="/outline"  className={(topData.page === 'objectives') ? "selected2" : ""}
                  onClick={goToObjectives}>
                    Objectives
                </a>
            </li>
            <li className="nav-item intNav1 navVar" key="studyDesign">
                <a href="/outline"  className={(topData.page === 'studyDesign') ? "selected2" : ""}
                  onClick={goToStudyDesign}>
                    Study Design
                </a>
            </li>
            <li className="nav-item intNav1 navVar" key="patientPopulation">
                <a href="/outline"  className={(topData.page === 'patientPopulation') ? "selected2" : ""}
                  onClick={goToPatient}>
                    Patient Population
                </a>
            </li>
            <li className="nav-item intNav1 navVar" key="_variables">
                <a href="/outline"  className={(topData.page === '_variables') ? "selected2" : ""}
                  onClick={goToVariables}>
                    Variables
                </a>
            </li>
            <li className="nav-item intNav1" key="edit">
                <a href="/outline"  className={(topData.page === 'edit')
                     ? "selected" : ""}
                  onClick={goToEdit}>
                    Document Text
                </a>
            </li>
            <li className="nav-item intNav1" key="synopsis">
                <a href="/outline"  className={(topData.page === 'synopsis') ? "selected" : ""}
                  onClick={goToSynopsis}>
                    Synopsis
                </a>
            </li>
        </ul>
    </>
  );
}

function DocumentOutletId(){
  const { documentData, setDocumentData } = useDocumentDataStore();

  // DEVIN: Pull DataFetching from inside the outlet to here?

  return (
    <Container className="mainPage">
        <Row>
            <h2 className="pageTitle">
                {documentData.documentName || "Untitled Protocol"}
            </h2>
        </Row>
        <Row> 
            <Col xs={3} className="sectionSidebar">
                <OutletSidebar />
            </Col>
            <Col>
                <OutletSwitch />            
            </Col>
        </Row>
    </Container>

);
}

export default DocumentOutletId;