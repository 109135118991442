import './App.css';
import { useEffect, useRef } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import { useVariableDataStore } from "./VariableDataStoreProvider";
import { Editor } from '@tinymce/tinymce-react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

function Trash() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#dddddd" className="bi bi-trash3-fill" viewBox="0 0 16 16">
      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
    </svg>    
  );
}

function Lines() {
  const { topData, setTopData }           = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { variableData, setVariableData } = useVariableDataStore();
  const editorRef = useRef(null);

  const isNumeric = (str) => {
    if (typeof str !== "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  const edit = (id) => {
    if (templateData.liveSection) {
      const label = templateData.lines.reduce((prev, curr) => {
        if (curr.id === templateData.liveSection) {
          return curr.label;
        }
        else {
          return prev;
        }
      }, "");
      
      setTemplateData({
        ...templateData,
        warning : `Please finish Editing: ${label}`,
      });
    }
    else {
      setTemplateData({
        ...templateData,
        liveSection : id,
      })
    }
  };
  
  // standardize so changes in white space won't show up as changes
  const different = (a, b) => {
    a = a || '';
    b = b || '';
      
    a = a.replace(/&nbsp;/g, ' ').replace(/^\s+|\s+$/g, '');
    b = b.replace(/&nbsp;/g, ' ').replace(/^\s+|\s+$/g, '');
    a = a.replace(/\s+/g, ' ');
    b = b.replace(/\s+/g, ' ');
    return (a !== b);
  };
  
  const findVars = (html) => {
    const variables = [];
    const m = [ ...html.matchAll(/data-tag="([^"]+)">([^<]+)</g) ];
    if (m.length) {
      m.map((match) => {
        const tag = match[1];
        const varSet = tag.match(/^(\w+)\./);
        const v = varSet[1];
        if(!variables.includes(v)) {
          // version will be template.variableData[vSet].version or latest                
          variables.push(v);
        }
      })
    }
    return variables.sort();
  };
  
  const contentEdited = () => {
    setTemplateData({
      ...templateData,
      saving   : true,
      warning  : "",
    });
    
    if (editorRef.current) {
      const innerContent = editorRef.current.getContent()
                         .replace(/&nbsp;/g, ' ')
                         .replace(/^\s+|\s+$/g, '')
                         .replace(/<\/span>(\w)/g, '</span> $1')
                         .replace(/(\w)<span /g, '$1 <span ')
                         ;
      const label = document.getElementsByClassName("lineLabel")[0].value;
      
      let updatedVariables = [];
      let line = {};
      if (templateData.liveSection.match(/^new-\d/)) {
        updatedVariables = findVars(innerContent);
        line.id          = templateData.liveSection;
        line.version     = 0;
        line.html        = "";
        line.hide        = false;
        line.align       = 'start';
      }
      else {
        templateData.lines.map((l) => {
          if (l.id === templateData.liveSection) {
            line = l;
            updatedVariables = findVars(innerContent);
          }
        });      
      }
                
      if (different(line.html, innerContent) || different(line.label, label)) {
        line.version = line.version + 1
        
        fetch(topData.endpoint + '/lines', {
          method : "PUT",
          body   : JSON.stringify({
            email        : topData.userEmail,
            token        : topData.token,
            Key          : topData.AccessKeyId,
            Secret       : topData.SecretAccessKey,
            nonce        : topData.nonce,
            groupName    : topData.groupName,
            userId       : topData.userId,
            templateId   : templateData.template_id,
            version      : templateData.template_version,
            draft_id     : templateData.draft_id,
            variables    : updatedVariables,
            line_id      : line.id,
            line_version : line.version,
            hide         : line.hide || false,
            align        : line.align || 'start',
            html         : innerContent,
            label        : label.replace(/^\s+|\s+$/g, ''),
            frontMatter  : templateData.frontMatter,
            selected     : templateData.selected,
          })
        })
        .then((res) => {
          if (401 === res.status) { 
            res.json().then((data) => {
    
              if (data.message) {
                alert(data.message);
              }
              else {
                alert('Your login has timed out'); 
              }
      
              setTopData(initialState);          
            })
          }
          else if (200 === res.status) {
            res.json().then((data) => {
            
              let updated = false
              const updatedDrafts = templateData.drafts.map((d) => {
                if (d.id == data.draft.id) {
                  updated = true;
                  return data.draft;
                }
                else {
                  return d;
                }
              });
              if (!updated) {
                updatedDrafts.push(data.draft);
              }
          
              setTemplateData({
                ...templateData,
                liveSection    : "", 
                saving         : false, 
                lines          : data.lines,  
                drafts         : updatedDrafts,
              });             
            });
          }   
        })
        .catch((err) => {
          setTemplateData({ ...templateData, saving : false, });
          console.log(err);
          alert("error 154-TitlePage");
        });
      }
    }
  };
  
  const s3_image_upload_handler = (blobInfo, progress) => {
    // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#images_upload_url
    return new Promise((resolve, reject) => {
      fetch(topData.endpoint + '/image', {
        method  : "POST",
        body    : JSON.stringify({
          email     : topData.userEmail,
          token     : topData.token,
          fileName  : blobInfo.filename(),
          Key       : topData.AccessKeyId,
          Secret    : topData.SecretAccessKey,
          nonce     : topData.nonce,
          groupName : topData.groupName,
        })
      })
      .then((response) => {
        if (200 === response.status) {
          response.json().then((data) => {
            let postData = new FormData()
            postData.append('key', data.response.fields.key);
            postData.append('AWSAccessKeyId', data.response.fields.AWSAccessKeyId);
            postData.append('policy', data.response.fields.policy);
            postData.append('signature', data.response.fields.signature);
            postData.append('file', blobInfo.blob(), blobInfo.filename());
            postData.append('Content-Type', blobInfo.blob().type);

            fetch(data.response.url, {
              method : "POST",
              body : postData
            })
            .then((resp) => {
              if (204 === resp.status) {
                const url = topData.endpoint + "/image?email=" + topData.userEmail + '&userId=' + topData.userId + "&filename=" + blobInfo.filename()  + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
                fetch(url, {
                  method : "GET",
                  cache : "no-cache",
                  headers : {
                    Authorization : topData.token,
                  }                  
                })
                .then((res) => {
                  if (200 === res.status) {
                    res.json().then((d) => {
                      resolve(d.url);
                    })
                  }
                  else {
                    res.json().then((data) => {
        
                      if (data.message) {
                        alert(data.message);
                      }
                      else {
                        alert('Your login has expired'); 
                      }
          
                      setTopData(initialState);          
                    })                    
                  }
                })
                .catch((error) => {
                  console.error('Error', error);           
                  reject({message: "Error getting file access.", remove: true});
                });                
              }
              else {
                reject({message: "Error uploading file.", remove: true});
              }
            })
            .catch((error) => {
              console.error('Error', error);           
              reject({message: "Error uploading file.", remove: true});
            });                
          })     
        }
        else {
          response.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
          
            setTopData(initialState);          
          })            
        }
      })
    }); 
  };  
  
  const deleteLine = (line_id) => {
    setTemplateData({
      ...templateData,
      deleteLine : line_id,
    });
    
    fetch(topData.endpoint + '/lines', {
      method : "DELETE",
      body : JSON.stringify({
        email        : topData.userEmail,
        token        : topData.token,
        Key          : topData.AccessKeyId,
        Secret       : topData.SecretAccessKey,
        nonce        : topData.nonce,
        groupName    : topData.groupName,
        userId       : topData.userId,
        templateId   : templateData.template_id,
        version      : templateData.template_version,
        draft_id     : templateData.draft_id,
        line_id      : line_id,
        selected     : templateData.selected,
      })
    })
    .then((res) => {
      if (401 === res.status) { 
        res.json().then((data) => {
  
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has timed out'); 
          }
    
          setTopData(initialState);          
        })
      }
      else if (200 === res.status) {
        res.json().then((data) => {
          if (templateData.draft_id) {
            setTemplateData({
              ...templateData,
              deleteLine : false,
              lines      : data.lines,  
            });          
          }
          else {
            setTemplateData({
              ...templateData,
              deleteLine : false,
              lines      : data.lines, 
              draft_id   : data.draft.draft_id,
              drafts     : [ ...templateData.drafts, data.draft ],
            });          
          }
        });
      }
    })
    .catch((err) => {
      setTemplateData({ ...templateData, deleteLine : false, });
      console.log(err);
      alert("error 336-TitlePage");
    });
  };

  return templateData.lines.map((l) => {
    return (
      <Row key={l.id} className="mb-3">
        <Col xs={2}>
          {
            (l.id === templateData.liveSection) ?
              <Button variant="success" onClick={contentEdited}>
                {
                  (l.id === templateData.liveSection && templateData.saving) ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />          
                  ) : (<>Save</>)
                }       
              </Button>
            :        
            <>     
              <Button onClick={() => edit(l.id)} variant="primary">
                Edit
              </Button>                    
 
 
              <Button onClick={() => deleteLine(l.id)} variant="danger" style={{marginLeft: "5px"}}>
                {
                  (templateData.deleteLine && templateData.deleteLine === l.id) ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />          
                  ) : <Trash /> 
                }                           
              </Button>
            </>
          }
          
        </Col>
        <Col xs={3}>
          {
            (templateData.liveSection === l.id) ?
            <Form.Control type="text" defaultValue={l.label} className="lineLabel" />
            : <strong>{l.label}:</strong>
          }
        </Col>
        {
          (templateData.liveSection === l.id) ?
          <Col xs={7}>
            {(variableData.variables) ? 
              <Editor
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={l.html + ' &nbsp; ' || ""}
                init={{
                  branding: false,
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist', 'lists', 'image', 'charmap',
                    'anchor', 'searchreplace', 'visualblocks', 
                    'insertdatetime', 'media', 'table', 'asclepia-group-variables'
                  ],
                  toolbar: 'undo redo | h1 h2 h3 | asclepia-group-variables |' +
                    'bold italic | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | table | image',
                  variables: variableData.variables,
                  images_upload_handler: s3_image_upload_handler,
                  file_picker_types: 'image',
                  image_advtab: true,
                  image_uploadtab: true,
                  images_file_types: 'jpeg,jpg,png,gif',
                  content_css: '/tiny.css',
                }}      
              />  
              :      <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />   }       

          </Col>      
          :
          <Col xs={7} dangerouslySetInnerHTML={{__html: l.html}}></Col>
        }       
      </Row>
    );
  });
}

function TitlePage() {
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { topData, setTopData }           = useTopDataStore();
  const { variableData, setVariableData } = useVariableDataStore();
  
  
  let dataFetching = false;
  useEffect(() => {
    window.scrollTo(0, 0);
    
    setTemplateData({
      ...templateData,
      liveSection : "",
      saving      : false,
    });    

    if (!dataFetching && !variableData.variables) {
      dataFetching = true;
      
      const url = topData.endpoint + "/variable?email=" + topData.userEmail + "&template_id=" + templateData.template_id + "&template_version=" + templateData.template_version + "&draft_id=" + templateData.draft_id + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
      fetch(url, {
        method : "GET",
        cache : "no-cache",
        headers : {
          Authorization : topData.token,
        }      
      })
      .then((res) => {
        if (200 === res.status) {
          res.json().then((returned) => {
            setVariableData({
              ...variableData,
              variables : returned.data,
            });  
            dataFetching = false;  
          });
        }
        else {
          res.json().then((data) => {
      
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
        
            setTopData(initialState);          
          });      
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });            
    }  

  }, []);
  
  const makeWord = () => {
    alert("Under development");
  };
  
  const gotoForm = (event) => {
    event.preventDefault();
    if (templateData.liveSection) {
      const label = templateData.lines.reduce((prev, curr) => {
        if (curr.id === templateData.liveSection) {
          return curr.label;
        }
        else {
          return prev;
        }
      }, "");
      
      setTemplateData({
        ...templateData,
        warning : `Please finish Editing: ${label}`,
      });
    }
    else {
      setTopData({ ...topData, page: "studyInformation", });
    
    }
  };

  const saveChanges = (event) => {
    event.preventDefault();
    setTopData({
      ...topData,
      page : 'templateSaveChoices',
    });
  };

  const goToVariables = (event) => {
    event.preventDefault();
    
    if (templateData.liveSection) {
      const label = templateData.lines.reduce((prev, curr) => {
        if (curr.id === templateData.liveSection) {
          return curr.label;
        }
        else {
          return prev;
        }
      }, "");
      
      setTemplateData({
        ...templateData,
        warning : `Please finish Editing: ${label}`,
      });
    }
    else {
      setVariableData({
        ...variableData,
        newVariants : 0,
        newVariableSets : 0,
      });
    
      setTopData({
        ...topData,
        page : 'variables',
      });    
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    if (templateData.liveSection) {
      const label = templateData.lines.reduce((prev, curr) => {
        if (curr.id === templateData.liveSection) {
          return curr.label;
        }
        else {
          return prev;
        }
      }, "");
      
      setTemplateData({
        ...templateData,
        warning : `Please finish Editing: ${label}`,
      });
    }
    else {
      setTopData({
        ...topData,
        page: "templateSetup",
      });    
    }
  };
  
  const gotoLayout = (e) => {
    e.preventDefault();
    if (templateData.liveSection) {
      const label = templateData.lines.reduce((prev, curr) => {
        if (curr.id === templateData.liveSection) {
          return curr.label;
        }
        else {
          return prev;
        }
      }, "");
      
      setTemplateData({
        ...templateData,
        warning : `Please finish Editing: ${label}`,
      });
    }
    else {
      setTopData({
        ...topData,
        page: "templateTitleLayout",
      });
    }
  };
  
  const addLine = () => {
    if (templateData.liveSection) {
      const label = templateData.lines.reduce((prev, curr) => {
        if (curr.id === templateData.liveSection) {
          return curr.label;
        }
        else {
          return prev;
        }
      }, "");
      
      setTemplateData({
        ...templateData,
        warning : `Please finish Editing: ${label}`,
      });
    }
    else {
      const lastAdded = templateData.lineAdded || 0;
      const addNum    = lastAdded + 1;
      setTemplateData({
        ...templateData,
        lineAdded : addNum,
        liveSection : `new-${addNum}`,
        lines : [{
          id : `new-${addNum}`,
          version : 1,
          label : 'Newly Added Line',
          html : "",
          variable_sets : []
        }, ...templateData.lines ]
      });    
    }
  };
        
  return (
    <Container className="p-3" id="contentContainer">
      <Row className="mb-3">
        <Col sm={12} style={{"marginBottom":"5px"}}>
          <h1 className="h2">Template {templateData.name}: Edit the title page.</h1>
          <p><span style={{"backgroundColor" : "#74FBEA"}}>
          Highlighted text</span> points out where your variables appear in the template. The 
          highlighting will not appear in the exported Word .docx document.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={goBack}
            >
              BACK: SECTIONS
            </Button>
          </div>              
        </Col>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={goToVariables}
            >
              MANAGE VARIABLES
            </Button>
          </div>              
        </Col>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={saveChanges}
            >
              SAVE
            </Button>
          </div>              
        </Col>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={gotoLayout}
            >
              LAYOUT
            </Button>
          </div>              
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="primary"
            style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
            onClick={addLine}
          >
            Add Line 
          </Button>
        </Col>
      </Row>

      {
        templateData.warning ? 
        <Row className="mb-3">
          <Col>
            <Alert key={'danger'} variant={'danger'}>
              {templateData.warning}
            </Alert>
          </Col>
        </Row>
        : ""            
      }


      <Lines />

    </Container>
  );
}

export default TitlePage;