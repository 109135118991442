import './App.css';
import { useEffect, useRef } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useDocumentDataStore } from "./DocumentDataStoreProvider";
import { useSynopsisDataStore } from "./SynopsisDataStoreProvider";
import { Editor } from '@tinymce/tinymce-react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import AsclepiaLib from "./AsclepiaLib";

function Plus() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
    </svg>
  );
}

function SingleSection({id}) {
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { topData, setTopData }           = useTopDataStore();
  const { synopsisData, setSynopsisData } = useSynopsisDataStore();
  const editorRef = useRef(null); 
  
  /*
    pulling in info based on id.   
    
    TODO: checking for updated variable values. 
  */
  
  const thisSection = synopsisData.synSections[id] ?
  synopsisData.synSections[id] :  
  synopsisData.sectionOptions.reduce((prev, curr) => {
    if (curr.id === id) { 
    
      // looking for variables which have been "deleted"
      if (curr.html) { 
        const regTag = /data-tag="(\w+)\./gm;
        for (let m of curr.html.matchAll(regTag)) {
          if (m[1]) {
            const vs = m[1];
            if (topData.deadVars[vs]) {
              if ("text" === topData.deadVars[vs].howDelete) {
                const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>([^<]+)</\\1>`, "mg");
                curr.html = curr.html.replaceAll(re, "$2");                    
              }
              else {
                const newVS = topData.deadVars[vs].changeTo;
                if (documentData.variableData[newVS]) {
                  const v = documentData.variableData[newVS].variables[0];
            
                  let newTag = `${newVS}.${v.id}`;
                  if (documentData.variableData[newVS].multiple) { newTag += '.1' }
            
                  let useValue = v.placeholder;
                  if (documentData.variableValues[v.id]) {
                    useValue = documentData.variableValues[v.id];
                  }
            
                  const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>[^<]+</\\1>`, "mg");
                  curr.html = curr.html.replaceAll(re, `<$1 style="background-color: #74FBEA;" data-tag="${newTag}">${useValue}</$1>`);                  
                }
                else {
                  const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>([^<]+)</\\1>`, "mg");
                  curr.html = curr.html.replaceAll(re, "$2");                    
                }
              }
            }
          }
        }
      }
    
      return {
        html : curr.html || "",
        name : curr.name || "",
        id   : id,
      };
    }
    else{ return prev; }
  }, {});
   
  const editRow = () => {
    if (synopsisData.editing && id === synopsisData.editing) {
      // save locally. 
      
      // TODO: capture changes to embedded variables. 

      const ss      = synopsisData.synSections[synopsisData.active.id] || synopsisData.active;
      ss.html       = editorRef.current.getContent().trim();
      setSynopsisData({
        ...synopsisData,
        synSections : { ...synopsisData.synSections, [synopsisData.active.id] : ss }, 
        editing     : false,
      });      
    }
    else {
      setSynopsisData({
        ...synopsisData,
        editing : id,
        active  : thisSection,
      });    
    }
  };

  const refreshRow = () => {
    // replace altered with section from protocol
    const reset = synopsisData.sectionOptions.reduce((prev, curr) => {
      if (curr.id === id) { 
        return {
          html : curr.html || "",
          name : curr.name || "",
          id   : id,
        };
      }
      else{ return prev; }
    }, {});

    const synData = {};
    const keys    = Object.keys(synopsisData.synSections);
    keys.map((sid) => {
      if (sid === id)  { 
        synData[sid] = reset;
      }
      else {
        synData[sid] = synopsisData.synSections[sid];
      }
    });
    
    setSynopsisData({
      ...synopsisData,
      synSections : synData,
    });
  };

  const changeRowLabel = (e) => {    
    const ss = synopsisData.synSections[synopsisData.active.id] || thisSection;
    ss.name  = e.target.value;
  
    setSynopsisData({
      ...synopsisData,
      synSections : { ...synopsisData.synSections, [synopsisData.active.id] : ss }, 
      active      : { ...synopsisData.active, name: e.target.value },
    });
  };

  const changeSection = (e) => {
    const section_id = e.target.value;
    
    const active = (synopsisData.synSections[section_id]) ? 
    synopsisData.synSections[section_id] :
    synopsisData.sectionOptions.reduce((prev, curr) => {
      if (curr.id === section_id) { 
        return {
          html : curr.html || "",
          name : curr.name || "",
          id   : section_id,
        };
      }
      else{ return prev; }
    }, {});
      
    setSynopsisData({
      ...synopsisData,
      active : active,
    });
  };

  const s3_image_upload_handler = (blobInfo, progress) => {
    // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#images_upload_url
    return new Promise((resolve, reject) => {
      fetch(topData.endpoint + '/image', {
        method  : "POST",
        body    : JSON.stringify({
          email     : topData.userEmail,
          token     : topData.token,
          fileName  : blobInfo.filename(),
          Key       : topData.AccessKeyId,
          Secret    : topData.SecretAccessKey,
          nonce     : topData.nonce,
          groupName : topData.groupName,
        })
      })
      .then((response) => {
        if (200 === response.status) {
          response.json().then((data) => {
            let postData = new FormData()
            postData.append('key', data.response.fields.key);
            postData.append('AWSAccessKeyId', data.response.fields.AWSAccessKeyId);
            postData.append('policy', data.response.fields.policy);
            postData.append('signature', data.response.fields.signature);
            postData.append('file', blobInfo.blob(), blobInfo.filename());
            postData.append('Content-Type', blobInfo.blob().type);

            fetch(data.response.url, {
              method : "POST",
              body : postData
            })
            .then((resp) => {
              if (204 === resp.status) {
                const url = topData.endpoint + "/image?email=" + topData.userEmail + '&userId=' + topData.userId + "&filename=" + blobInfo.filename()  + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
                fetch(url, {
                  method : "GET",
                  cache : "no-cache",
                  headers : {
                    Authorization : topData.token,
                  }                  
                })
                .then((res) => {
                  if (200 === res.status) {
                    res.json().then((d) => {
                      resolve(d.url);
                    })
                  }
                  else {
                    res.json().then((data) => {
        
                      if (data.message) {
                        alert(data.message);
                      }
                      else {
                        alert('Your login has expired'); 
                      }
          
                      setTopData(initialState);          
                    })                    
                  }
                })
                .catch((error) => {
                  console.error('Error', error);           
                  reject({message: "Error getting file access.", remove: true});
                });                
              }
              else {
                reject({message: "Error uploading file.", remove: true});
              }
            })
            .catch((error) => {
              console.error('Error', error);           
              reject({message: "Error uploading file.", remove: true});
            });                
          })     
        }
        else {
          response.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
          
            setTopData(initialState);          
          })            
        }
      })
    }); 
  };
  
  return(
    <Row className="docSection synSection" key={id}>
      <div className="synForm">
      <Form style={{"marginBottom":"0"}}>
        {
          (synopsisData.editing && id === synopsisData.editing) ? 
            <Form.Group 
              className="dataGroup synGroup" 
              key={`${id}-label`} 
            >
                <Form.Label 
                  className="dataLabel synLabel"
                >
                  Row label
                </Form.Label>
                <Form.Control 
                  type="text" 
                  value={synopsisData.active.name} 
                  onChange={changeRowLabel}
                  className="dataLiveType synLiveType"
                />
            </Form.Group>          
          : <div className="dataGroup synGroup"  >
                <p className="temporary dataLiveType synLiveType">
                  <span className="dataLabel synLabel temporary2">Row Label</span>
                  {thisSection.name}
                </p>
            </div>
 
        }
        {
          (synopsisData.editing && id === synopsisData.editing) ? 
          <Form.Group 
            className="dataGroup synGroup" 
            key={`${id}-section`} 
            style={{display:'flex', marginTop: 10}}
          >
            <Form.Label 
              className="dataLabel synLabel"
            >
              Based on
            </Form.Label>         
            <Form.Select 
              className="dataDrop synLiveType"
              aria-label="Select Section" 
              onChange={changeSection}
            >
              <option>Select a section from the existing Document</option>
              {
                synopsisData.sectionOptions.map((section) => {
                  return (
                    <option value={section.id} key={section.id} selected={(id === section.id)}>
                      {`${section.num}. ${section.name}`}
                    </option>
                  );
                })
              }
            </Form.Select>        
          </Form.Group>
          : <></>         
        }
      </Form>
      </div>
      <div className="synButtonGrp">  {/*Aimee: Changed from a Row*/}
        <Button 
          onClick={editRow} 
          className="docEditButton docEditSaveButton synEditBtn"
        >
          {
            (synopsisData.editing && id === synopsisData.editing) ? <>Save</> : <>Edit</>
          }
        </Button>  
        {
        (synopsisData.editing && id === synopsisData.editing) ? <></> :        
        <Button 
          onClick={refreshRow}   
          className="docEditButton docEditSaveButton synEditBtn"
        >
          Refresh
        </Button>  
        }
      </div> 
      {
        (synopsisData.editing && id === synopsisData.editing) ? 
          <Editor
            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={synopsisData.active.html}
            init={{
              branding: false,
              height: 150,
              menubar: false,
              plugins: [
                'advlist', 'lists', 'image', 'charmap',
                'anchor', 'searchreplace', 'visualblocks', 
                'insertdatetime', 'media', 'table'
              ],
              toolbar: 'undo redo | h1 h2 h3 | ' +
                'bold italic | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | table | image',
              images_upload_handler: s3_image_upload_handler,
              file_picker_types: 'image',
              image_advtab: true,
              image_uploadtab: true,
              images_file_types: 'jpeg,jpg,png,gif',
              content_css: '/tiny.css',
              /* 
              init_instance_callback: (editor) => {
                editor.on('mousedown', (e) => {
                  setDocumentData({ ...documentData, saved : false });
                });
              }
              */          
            }}      
          />         
        : <div dangerouslySetInnerHTML={{__html: thisSection.html}} className="templateTextPreview synPreview"/>      
      }
    </Row>
  );
}

function Sections() {
  const { synopsisData, setSynopsisData } = useSynopsisDataStore();

  const keys = Object.keys(synopsisData.synSections) || [];
  return keys.map((id) => {
    return (<SingleSection id={id} key={id} />);
  });
}

function EditSynopsis() {  
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { synopsisData, setSynopsisData } = useSynopsisDataStore();
  const { topData, setTopData }           = useTopDataStore();

  const newRow = () => {
    const section = synopsisData.sectionOptions.reduce((prev, curr) => {
      if (prev) { return prev; }
      else {
        if (synopsisData.synSections[curr.id]) { return prev; }
        else { return curr; }
      }
    }, false);
    
    setSynopsisData({
      ...synopsisData,
      synSections : { ...synopsisData.synSections, [section.id] : {
        html : section.html || "",
        name : section.name || "",
        id   : section.id,
      }}
    });
    
    window.setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 10);    
  };
  const saveSynopsis = () => {
    // save synopsis to backend to create continuity. Store as JSON in S3 
    setSynopsisData({
      ...synopsisData,
      saving : true,
    });
    
    fetch(topData.endpoint + "/document", {
      method : "PUT",
      body   : JSON.stringify({
        email       : topData.userEmail,
        token       : topData.token,
        Key         : topData.AccessKeyId,
        Secret      : topData.SecretAccessKey,
        nonce       : topData.nonce,
        groupName   : topData.groupName,
        id          : documentData.documentId,
        version     : documentData.version,
        synSections : synopsisData.synSections,
      })
    })
    .then((response) => {
      if (200 === response.status) {
        console.log(response);
        
        setSynopsisData({
          ...synopsisData,
          saving : false,
        });        
      }
      else {
        response.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }
          
          setTopData(initialState);          
        })            
      }

    })
  };

  let fetching = false;
  useEffect(() => {
    const document = topData.existing.reduce((prev, curr) => {
      if (curr.documentId === topData.document_id) {
        return curr;
      }
      else { return prev; }
    }, {});
    
    setSynopsisData({
      ...synopsisData,
      loading : true,
    }); 

    setDocumentData({
      ...documentData,
      ...document,
    });
    
    if (!fetching) {
      fetching = true;
      
      const param = [topData.userEmail, topData.userId, topData.AccessKeyId, topData.SecretAccessKey, topData.nonce, topData.groupName, topData.document_id, 'Synopsis'];        
      const url   = topData.endpoint + "/document?p=" + param.join(',');
      fetch(url, {
        method  : "GET",
        cache   : "no-cache",
        headers : {
          Authorization : topData.token,
        }                  
      })
      .then((res) => {
        if (200 === res.status) {
          res.json().then((data) => {
            const synSections = data.content;
            
            const sections       = AsclepiaLib.flatten(document.sections);
            const sectionOptions = sections.reduce((prev, curr) => {
              const c = (curr.num.match(/\./g) || []).length;
              if (c < 2) {
                prev.push(curr);
              }
              return prev;
            }, []);
            
            setSynopsisData({
              ...synopsisData,
              sections       : sections,
              sectionOptions : sectionOptions,
              synSections    : synSections,
              loading        : false,
            });
          })
        }
      })
    }
  }, []);
  
  
  return (
    <Container className="subPage">
      <Row className="subPageRow editRow"> 
        <Col md="3">
          <p className="docLinkItem" id="newSection"><a href="#id" onClick={newRow}>
            <Plus />Add New Row
          </a></p>
        </Col>
      </Row>
      <Row className="subPageRow editRow">
        { synopsisData.loading ? 
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          /> : <Sections /> }
      </Row>     
      <Row>
        <Col className="topButtonGroup">   
          <Button className="intNavButton dataBtn" 
            id="dataTopButton"
            onClick={saveSynopsis} 
          >
            {documentData.saving && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            {!documentData.saving && "Save"}
          </Button>   
          </Col>
      </Row>
    </Container>
  );
  
}

export default EditSynopsis;