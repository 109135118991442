import './App.css';
import { useEffect, useRef } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useDocumentDataStore } from "./DocumentDataStoreProvider";
import { Editor } from '@tinymce/tinymce-react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';

function Sections() {
  const { topData, setTopData }           = useTopDataStore();
  const { documentData, setDocumentData } = useDocumentDataStore();
  const editorRef = useRef(null);

  const editSection = (sectionNumber, id) => {
    if( !documentData.liveSection ) {
      setDocumentData({ ...documentData, liveSection : sectionNumber, liveId : id});    
    }
    else {
      alert(`Please Finish Editing & Saving Section ${documentData.liveSection} before Editing ${sectionNumber}`);
    }
  };

  const s3_image_upload_handler = (blobInfo, progress) => {
    // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#images_upload_url
    return new Promise((resolve, reject) => {
      fetch(topData.endpoint + '/image', {
        method  : "POST",
        body    : JSON.stringify({
          email     : topData.userEmail,
          token     : topData.token,
          fileName  : blobInfo.filename(),
          Key       : topData.AccessKeyId,
          Secret    : topData.SecretAccessKey,
          nonce     : topData.nonce,
          groupName : topData.groupName,
        })
      })
      .then((response) => {
        if (200 === response.status) {
          response.json().then((data) => {
            let postData = new FormData()
            postData.append('key', data.response.fields.key);
            postData.append('AWSAccessKeyId', data.response.fields.AWSAccessKeyId);
            postData.append('policy', data.response.fields.policy);
            postData.append('signature', data.response.fields.signature);
            postData.append('file', blobInfo.blob(), blobInfo.filename());
            postData.append('Content-Type', blobInfo.blob().type);

            fetch(data.response.url, {
              method : "POST",
              body : postData
            })
            .then((resp) => {
              if (204 === resp.status) {
                const url = topData.endpoint + "/image?email=" + topData.userEmail + '&userId=' + topData.userId + "&filename=" + blobInfo.filename()  + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
                fetch(url, {
                  method : "GET",
                  cache : "no-cache",
                  headers : {
                    Authorization : topData.token,
                  }                  
                })
                .then((res) => {
                  if (200 === res.status) {
                    res.json().then((d) => {
                      resolve(d.url);
                    })
                  }
                  else {
                    res.json().then((data) => {
        
                      if (data.message) {
                        alert(data.message);
                      }
                      else {
                        alert('Your login has expired'); 
                      }
          
                      setTopData(initialState);          
                    })                    
                  }
                })
                .catch((error) => {
                  console.error('Error', error);           
                  reject({message: "Error getting file access.", remove: true});
                });                
              }
              else {
                reject({message: "Error uploading file.", remove: true});
              }
            })
            .catch((error) => {
              console.error('Error', error);           
              reject({message: "Error uploading file.", remove: true});
            });                
          })     
        }
        else {
          response.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
          
            setTopData(initialState);          
          })            
        }
      })
    }); 
  };

  const editableText = (sectionNumber, s) => {
    if ('liveSection' in documentData && sectionNumber === documentData.liveSection) {
      return (
        <div className="editorBox">
        <Editor
          tinymceScriptSrc={'/tinymce/tinymce.min.js'}
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={s.html || ""}
          init={{
            branding: false,
            height: 500,
            menubar: false,
            plugins: [
              'advlist', 'lists', 'image', 'charmap',
              'anchor', 'searchreplace', 'visualblocks', 
              'insertdatetime', 'media', 'table'
            ],
            toolbar: 'undo redo | h1 h2 h3 | ' +
              'bold italic | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | table | image',
              
            images_upload_handler: s3_image_upload_handler,
            file_picker_types: 'image',
            image_advtab: true,
            image_uploadtab: true,
            images_file_types: 'jpeg,jpg,png,gif',
            images_upload_url: 'https://example.com',
            content_css: '/tiny.css',
            init_instance_callback: (editor) => {
              editor.on('mousedown', (e) => {
                setDocumentData({ ...documentData, saved : false });
              });
            }          
          }}      
        />   
        </div>         
      );
    }
    else if (s.html) {  
      // temporary, conversion from underline to highlight
      if (s.html) {     
        s.html = s.html.replace(/text-decoration:\s*underline; text-decoration-color:\s*red; text-decoration-thickness:\s*4px;/g, 'background-color: #74FBEA;' );          
      }

      // looking for variables which have been "deleted"
      if (s.html) { 
        const regTag = /data-tag="(\w+)\./gm;
        for (let m of s.html.matchAll(regTag)) {
          if (m[1]) {
            const vs = m[1];
            if (topData.deadVars[vs]) {
              if ("text" === topData.deadVars[vs].howDelete) {
                const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>([^<]+?)</\\1>`, "mg");
                s.html = s.html.replaceAll(re, "$2");                    
              }
              else {
                const newVS = topData.deadVars[vs].changeTo;
                if (documentData.variableData[newVS]) {
                  const v = documentData.variableData[newVS].variables[0];
                
                  let newTag = `${newVS}.${v.id}`;
                  if (documentData.variableData[newVS].multiple) { newTag += '.1' }
                
                  let useValue = v.placeholder;
                  if (documentData.variableValues[v.id]) {
                    useValue = documentData.variableValues[v.id];
                  }
                
                  const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>[^<]+?</\\1>`, "mg");
                  s.html = s.html.replaceAll(re, `<$1 style="background-color: #74FBEA;" data-tag="${newTag}">${useValue}</$1>`);                  
                }
                else {
                  const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>([^<]+?)</\\1>`, "mg");
                  s.html = s.html.replaceAll(re, "$2");                    
                }
              }
            }
          }
        }
      }

      return (
        <Card.Text className="templateTextPreview" dangerouslySetInnerHTML={{__html: s.html}} />);
    }
    else {
      return (<></>);
    }
  };

  const isNumeric = (str) => {
    if (typeof str !== "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }
  
  const contentEdited = (e) => {
    e.preventDefault();
    
    setDocumentData({ ...documentData, saving: true, });
    
    if (editorRef.current) {
      const innerContent = editorRef.current.getContent()
                         .replace(/^\s+|\s+$/g, '')
                         .replace(/<\/span>(\w)/g, '</span> $1')
                         .replace(/(\w)<span /g, '$1 <span ')
                         ;

      const recursiveUpdate = (sections, updatedVariables) => {
        sections.map((s) => {
          if (s.id === documentData.liveId) {
            s.html = innerContent;
            
            const m = [ ...s.html.matchAll(/data-tag="([^"]+)">([^<]+)</g) ];
            if (m.length) {
              m.map((match) => {
                const tag = match[1];
                const val = match[2];
              
                const t = tag.match(/\.\d+$/);
                const p = tag.match(/\./g).length;
                if (t && p > 1) {
                  // these are "multiple" variables.                   
                  const v = tag.split('.');
                  const e = documentData.variableValues[v[1]];
                  e = (Array.isArray(e)) ? e : [e];
                  const placeholder = documentData.variableData[v[0]].variables.reduce((prev, curr) => {
                    if (curr.id === v[1]) {
                      return curr.placeholder;
                    }
                    else {
                      return prev;
                    }
                  }, "");
                  if (isNumeric(v[2])) {
                    const c = parseInt(v[2]);
                    let end = e.length;
                    if (c >= e.length) {
                      end = c;
                    }
                    for (let i=0; i<end; i++) {
                      if ((i+1) === c) {
                        e[i] = val;
                      }
                      else {
                        if (!e[i]) {
                          e[i] = `${placeholder}.${(i+1)}`;
                        }
                      }
                    }
                    updatedVariables[v[1]] = e;
                  }
                }
                else {
                  const v = tag.split('.')[1];                  
                  if (documentData.variableValues[v] !== val) {
                    updatedVariables[v] = val;
                  }
                }
              });              
            }            
          }
          else if (s.sub_sections) {
            recursiveUpdate(s.sub_sections, updatedVariables)
          }
        });
        
        return sections;
      }
      
      const updatedVariables = {};
      const sections = recursiveUpdate(documentData.sections, updatedVariables);
            
      const mergedVariables = {};
      Object.keys(documentData.variableValues).map((vid) => {
        if (vid in updatedVariables) {
          mergedVariables[vid] = updatedVariables[vid];
        }
        else {
          mergedVariables[vid] = documentData.variableValues[vid];
        }
      });
      
      fetch(topData.endpoint + '/document', {
        method : "PUT",
        body   : JSON.stringify({
          email            : topData.userEmail,
          token            : topData.token,
          userId           : topData.userId,
          userName         : topData.userName,
          id               : documentData.documentId,
          version          : documentData.version,
          template_id      : documentData.template_id || topData.template_id,
          template_version : documentData.template_version || topData.template_version,
          variableValues   : mergedVariables,
          documentName     : documentData.documentName,
          sections         : sections,
          Key              : topData.AccessKeyId,
          Secret           : topData.SecretAccessKey,
          nonce            : topData.nonce,
          groupName        : topData.groupName,
        })
      })
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has timed out'); 
            }
          
            setTopData(initialState);          
          })
        }
        else if (200 === res.status) {
          setDocumentData({
            ...documentData,
            liveSection    : 0, 
            liveId         : 0,
            saving         : false, 
            sections       : sections,  
            variableValues : mergedVariables,   
          });
        }   
      })
      .catch((err) => {
        setDocumentData({ ...documentData, loading : false, saving : false, jumpto : "", });
        console.log(err);
        alert("error 300-DataForm");
      });
    }
    else {
      alert("no editorRef");
    }
  };

  const recursiveSections = (sections, prefix, cards) => {
    let count = 0;
    sections.map((s) => {
      count++;
      const sectionNumber = `${prefix}${count}`;
      cards.push(
        <Row 
          key={`section${sectionNumber}`}
          className="docEditSection docSection" 
        >
          <Col xs={2} className="docCol1">
          {
            ('liveSection' in documentData && sectionNumber === documentData.liveSection) ?
              <Button 
                variant="outline-success" 
                onClick={contentEdited}
                className="docEditSaveButton docSaveButton"
              >
                {
                  documentData.saving ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />          
                  ) : (<>Save Section {sectionNumber}</>)
                }       
              </Button>
            : 
            <Button 
                onClick={() => editSection(sectionNumber, s.id)} 
                variant="outline-primary"
                className="docEditButton docSaveButton docEditSaveButton"
            >
              Edit Section {sectionNumber}
            </Button>            
          }
          
          </Col>
          <Col xs={10} className="docCol2">
            <Card.Header className="docCardHead docSectionTitle">
              {sectionNumber} {s.name}
            </Card.Header>
            <Card.Body className="docCardBody">
            {
              s.guidance ? 
                <Card.Subtitle 
                  className="guidanceTextPreview" 
                  dangerouslySetInnerHTML={{__html: `<em>${s.guidance}</em>`}} />
              : <></>
            }
            {editableText(sectionNumber, s)}
            </Card.Body>
          </Col>
        </Row>      
      );

      if (s.sub_sections) {
        recursiveSections(s.sub_sections, `${sectionNumber}.`, cards);
      }
    });       
  }
  const cards = [];
  recursiveSections(documentData.sections, "", cards);
  return cards;
}

function EditDoc() {
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { topData, setTopData }           = useTopDataStore();
     
  const setupPage = () => {
    const recurseSub = (sections) => {
      sections.map((s) => {
          if (s.html && s.html.length && s.variable_sets && s.variable_sets.length) {  
        
            // temporary, conversion from underline to highlight
            if (s.html) {     
              s.html = s.html.replace(/text-decoration:\s*underline; text-decoration-color:\s*red; text-decoration-thickness:\s*4px;/g, 'background-color: #74FBEA;' );          
            }
          
            // looking for variables which have been "deleted"
            if (s.html) { 
              const regTag = /data-tag="(\w+)\./gm;
              for (let m of s.html.matchAll(regTag)) {
                if (m[1]) {
                  const vs = m[1];
                  if (topData.deadVars[vs]) {
                    if ("text" === topData.deadVars[vs].howDelete) {
                      const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>([^<]+?)</\\1>`, "mg");
                      s.html = s.html.replaceAll(re, "$2");                    
                    }
                    else {
                      const newVS = topData.deadVars[vs].changeTo;
                      if (documentData.variableData[newVS]) {
                        const v = documentData.variableData[newVS].variables[0];
                
                        let newTag = `${newVS}.${v.id}`;
                        if (documentData.variableData[newVS].multiple) { newTag += '.1' }
                
                        let useValue = v.placeholder;
                        if (documentData.variableValues[v.id]) {
                          useValue = documentData.variableValues[v.id];
                        }
                
                        const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>[^<]+?</\\1>`, "mg");
                        s.html = s.html.replaceAll(re, `<$1 style="background-color: #74FBEA;" data-tag="${newTag}">${useValue}</$1>`);                  
                      }
                      else {
                        const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>([^<]+?)</\\1>`, "mg");
                        s.html = s.html.replaceAll(re, "$2");                    
                      }
                    }
                  }
                }
              }
            }
                
            s.variable_sets.map((vset) => {
              if (documentData.variableData[vset.id]) {
                documentData.variableData[vset.id].variables.map((v) => {
                  if (documentData.variableData[vset.id].multiple) { 
                    let i = 0;
                    try {
                      documentData.variableValues[v.id].map((vi) => {
                        i++;
                        if (documentData.variableValues[v.id][(i-1)]) {
                          const replaceMe   = `data-tag="${vset.id}\\.${v.id}\\.${i}">[^<]+?<`;
                          const re          = new RegExp(replaceMe, "mg");
                          s.html            = s.html.replace(re, `data-tag="${vset.id}.${v.id}.${i}">${documentData.variableValues[v.id][(i-1)]}<`);                  
                        }
                      });                                
                    }
                    catch (error) {
                      if (String(error).match(/map is not a function/)) {
                        setTimeout(() => {
                          setDocumentData({
                            ...documentData,
                            variableValues : { ...documentData.variableValues, [v.id] : [documentData.variableValues[v.id],], },
                          });                    
                        }, 500);
                      }
                      return false;
                    }
              
                    const placeholder = documentData.variableData[vset.id].variables.reduce((prev, curr) => {
                      if (curr.id === v.id) {
                        return curr.placeholder;
                      }
                      else {
                        return prev;
                      }
                    }, "");
                    const e   = documentData.variableValues[v.id];
                    const end = e.length;
                    for (i=0; i<end; i++) {
                      let j = i+1;
                      if (!e[i]) {
                        e[i] = `${placeholder}.${j}`;
                      }
                    }
              
                    const title = placeholder.replace(/^#/, '');
              
                    const findCommas = `<span style="background-color: #74FBEA;".*? data-tag="${vset.id}\\.${v.id}\\.comma">[^<]+?<\\/span>`;
                    const commaRe    = new RegExp(findCommas, "mg");
                    i = 0
                    const commaList  = e.map((item) => {
                      const k = i+1;
                      const l = `<span style="background-color: #74FBEA;" title="${title}.${k}" data-tag="${vset.id}.${v.id}.${k}">${e[(i)]}</span>`;                  
                      i++;
                      return l;
                    }).join(', ');
                    s.html = s.html.replace(commaRe, commaList);
              
                    const findBullet = `<span style="background-color: #74FBEA;".*? data-tag="${vset.id}\\.${v.id}\\.bullet">[^<]+?<\\/span>`;
                    const bulletRe   = new RegExp(findBullet, "mg");
                    i = 0;
                    const bulletList = e.map((item) => {
                      const k = i+1;
                      const l = `<li><span style="background-color: #74FBEA;" title="${title}.${k}" data-tag="${vset.id}.${v.id}.${k}">${e[(i)]}</span></li>`;
                      i++;
                    }).join("");
                    const ul = `<ul>${bulletList}</ul>`;
                    s.html = s.html.replace(bulletRe, ul);
                  }
                  else {
                    if (documentData.variableValues[v.id]) {
                      const replaceMe   = `data-tag="(${vset.id}\\.${v.id})">[^<]+?<`;
                      const re          = new RegExp(replaceMe,"mg");
                      s.html            = s.html.replace(re, `data-tag="${vset.id}.${v.id}">${documentData.variableValues[v.id]}<`);
                    }
                  }
                });                        
              }
            });
          }
          if (s.sub_sections) {
            recurseSub(s.sub_sections);
          }
        });     
    };
  
    let newSections = documentData.sections || [];
    recurseSub(newSections);
    setDocumentData({
      ...documentData,
      sections: newSections,
    });    
  }
     
  useEffect(() => {
    window.scrollTo(0, 0);
    
    const now = new Date().getTime();
    if (now > (topData.docs_at + 7200 * 1000)) {
      const param = [topData.userEmail, topData.userId, topData.AccessKeyId, topData.SecretAccessKey, topData.nonce, topData.groupName];        
      const url   = topData.endpoint + "/document?p=" + param.join(',');
      fetch(url, {
        method : "GET",
        cache : "no-cache",
        headers : {
          Authorization : topData.token,
        }      
      })
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {
  
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
    
            setTopData(initialState);          
          })
        }
        else if (200 === res.status) {
          res.json().then((documentData) => {
            const localTimestamp = new Date().getTime();
            setTopData({ 
              ...topData, 
              existing : documentData['documents'], 
              docs_at  : now,
            });
            
            setupPage();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert('error retrieving existing documents');
      });
    }
    else {
      setupPage();
    }
 
/* 
      const imgTerm = `https://s3.amazonaws.com/${topData.groupName}-images.asclepiagroup.com/([^"]+)`;
      const regexp  = new RegExp(imgTerm,"g");
      const matches = [ ...s.html.matchAll(regexp) ];
      if (matches.length) {
        for (let i=0; i<matches.length; i++) {
          fetch(matches[i][0], {
            method  : "GET",
            headers : {
              'Access-Control-Allow-Origin' : '*',
            }     
          }) 
          .then((response) => {
            console.log(response);
            if (403 === response.status) { 
              s.html.replace(matches[i][0], 'https://currentmillis.com/images/milliseconds.png');
            }
          })         
          .catch((err) => {
            console.error(err);
          });      
        }
      }
 
*/
  }, [documentData.variableValues]);
 
  const makeWord = () => {
    if( documentData.liveSection ) {
      alert(`Please Finish Editing & Saving Section ${documentData.liveSection} first`);
      return false;
    }

    const clean_re = new RegExp('<(span|td) [^>]+? data-tag="[^>]+?>([^<]+?)</\\1>', "mg");

    const recurseContent = (sections, level, prefix) => {
      let count      = 0;
      let content    = "";
      
      sections.map((s) => {
        count++;
        content += `<h${level}>${prefix}${count}. ${s.name}</h${level}>`;
        if (s.html) {
          content += s.html.replaceAll(clean_re, "$2");
        }
        if (s.sub_sections) {
          content += recurseContent(s.sub_sections, (level+1), `${prefix}${count}.`);
        }
      });
      return content;
    }
    const innerContent = recurseContent(documentData.sections, 1, '');

    const HTMLcontent = `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="utf-8" />
<title>Protocol Generated by Asclepia Kinetika</title>
</head>
<body>
${innerContent}
</body>
</html>
`;
          
    setDocumentData({ ...documentData, loading : true });    
  
    fetch(topData.endpoint + "/word", {
      method : "POST",
      body : JSON.stringify({ 
        content   : HTMLcontent.replace(/\s+/g, ' '),
        email     : topData.userEmail,
        token     : topData.token,
        Key       : topData.AccessKeyId,
        Secret    : topData.SecretAccessKey,
        nonce     : topData.nonce,
        groupName : topData.groupName,
      })
    })
    .then((res) => {
      setDocumentData({ ...documentData, loading : false });
    
      if (400 === res.status) {
        res.json().then((data) => {
          console.log(data)
        });
        alert('Error from server');
      }
      else if (200 === res.status) {
        res.json().then((data) => {
          window.location.href = data.url;
        });
      }
    })
    .catch((err) => {
      setDocumentData({ ...documentData, loading : false });
      console.log(err);
    });    
  };  
  
  const makeJSON = () => {
    alert('Not Implemented');
    return false;
//     const j = JSON.stringify({
//       CONDITION : {"full" : documentData.condition.full, "abbr" : documentData.condition.abbr},
//       CONDITIONSEV : {"full" : documentData.conditionSev.full, "abbr" : documentData.conditionSev.abbr},
//       EXPOSURE : documentData.exposure,
//     });
//     setDocumentData({ ...documentData, isLoading : true });
//     fileDownload(j, "protocolData.json");
//     setDocumentData({ ...documentData, isLoading : false });
  };
  
  const gotoForm = (event) => {
    event.preventDefault();
    if( !documentData.liveSection ) {
      setTopData({ ...topData, page: "studyInformation", });   
    }
    else {
      alert(`Please Finish Editing & Saving Section ${documentData.liveSection} before changing views`);
    }
  };
          
  return (
    <Container className="subPage">
      <Sections />
      <Row className="subPageRow editRow">
        <Col xs="12" className="topButtonGroup">
          <Button className="intNavButton dataBtn"  
            onClick={makeWord} 
            size="sm"
          >
            {documentData.loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!documentData.loading && "Download as Word .docx"}
          </Button>&nbsp;&nbsp;&nbsp;  
          <Button 
            onClick={makeJSON} 
            className="intNavButton dataBtn" 
          >
            Generate JSON 
          </Button>
        </Col>
      </Row>
      {/* AIMEE: Eventually this should move to top or a pop-up*/}
      <Row>
        <Col style={{"marginTop":"15px"}}>
          {
            documentData.saved && (
            <Alert key={'data-saved'} variant={'success'}>
              Edits Saved.
            </Alert>                    
            )
          }
        </Col>
      </Row>
    </Container>
  );
}

export default EditDoc;