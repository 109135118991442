import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';


function TemplateSaveChoices() {
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { topData, setTopData }           = useTopDataStore();

  const process_sections = (sections, status) => {
    const linearize = (tree, list, parent) => {
      let i = 1;
      tree.map((s) => {
        if (!s['id'] || !templateData.selected.includes(s['id'])) {
          return false;
        }
        
        if (s['id'].match(/^newSection/)) {
          s['changed'] = true;
        } 
        else {
          s['changed'] = false;
        }
        
        s['parent'] = parent;
        if ('top' === parent) {
          s['order'] = i;
          i++;
        }
        s['children'] = [];
        
        if (s['sub_sections'] && s['sub_sections'].length) {
          const subs = s['sub_sections'].reduce((prev, ss) => {
            if (ss['id'] && templateData.selected.includes(ss['id'])) {
              s['children'].push(ss['id']);
              return [ ...prev, ss ];
            }
            else {
              return prev;
            }
          }, []);
          s['sub_sections'] = subs;
          
          linearize(s['sub_sections'], list, s['id']);
        }
        list.push(s);        
      });
    }
      
    const list = [];

    linearize(sections, list, 'top');
        
    const findPart = (sections, part, id) => {
      return sections.reduce((prev, s) => {
        if (s['id'] === id) {
          return s[part] || "";
        }
        else if(s['sub_sections']) {
          return findPart(s['sub_sections'], part, id) || prev;
        }
        else {
          return prev;
        }
      }, '');
    }
    
    const findSection = (id, sections) => {
      let found = false;
      return sections.reduce((prev, curr) => {
        if (found) {
          return prev;
        }
        else if (curr.id === id) {
          found = true;
          return curr;
        }
        else if (curr.sub_sections && curr.sub_sections.length) {
          const s = findSection(id, curr.sub_sections);
          if (s.id) { return s; }
          else { return prev; }
        }
        else {
          return prev;
        }        
      }, {});
    }
    
    const unalteredDetails = () => {
      return templateData.templates.reduce((prev, curr) => {
        if (curr.id === templateData.template_id && curr.version === templateData.template_version) {
          return curr;
        }
        else {
          return prev;
        }
      }, {sections: []});
    }
    
    const differentSubSections = (s) => {
      if (!s.id) { return false; }
    
      const details   = unalteredDetails();
      const unaltered = findSection(s.id, details.sections);
      
      if (!s.sub_sections) { s.sub_sections = []; }
      if (!unaltered.sub_sections) { unaltered.sub_sections = []; }
      
      if (s.sub_sections.length != unaltered.sub_sections.length) {
        return true;
      }
      else if (0 === s.sub_sections.length) {
        return false;
      }
      else {
        let i = 0;
        return s.sub_sections.reduce((prev, curr) => {
          const same = (curr.id === unaltered.sub_sections[i].id);
          i++;
          if (!same) { 
            return true; 
          }
          else { return prev; }
        }, false);
      }
    }
    
    const different = (a, part, id) => {
      a = a || '';
      
      // need to find the unaltered version, as last downloaded, for comparison
      const details = unalteredDetails();
      
      let b = findPart(details.sections, part, id);
    
      a = a.replace(/^\s+|\s+$/g, '');
      b = b.replace(/^\s+|\s+$/g, '');
      a = a.replace(/\s+/g, ' ');
      b = b.replace(/\s+/g, ' ');
      return (a !== b);
    };
    
    let process_count = 0;

    const updated = [];
    
    const process_next_leaf = (list) => {
      const s = list.reduce((prev, curr) => {
        if (prev) { return prev; }
        else {
          if (curr.children && curr.children.length) {
            return prev;
          }
          else {
            if (!curr['changed'] && differentSubSections(curr)) {
              curr['changed'] = true;
            }
            else if ( !curr['changed'] && (different(curr['guidance'], 'guidance', curr.id) || different(curr['html'], 'html', curr.id) || different(curr['name'], 'name', curr.id) ) ) {
              curr['changed'] = true;
            }
            
            return curr;
          }
        }
      }, false);
      
      if (s) {
        if (s['changed']) {
          const variable_sets = [];
          const re = /data-tag="(\w+)\./g;
          let   m  = [];
          while ((m = re.exec(s.html)) !== null) {
            variable_sets.push(m[1]);
          }
          
          if ('version' === status) {
            setTemplateData({
              ...templateData,
              versionSaving : `saving ${s.name} section...`,
              loading2      : true,
            });         
          }
          else {
            setTemplateData({
              ...templateData,
              loading3     : true,
              branchSaving : `saving ${s.name} section...`,
            });         
          }

          fetch(topData.endpoint + '/section', {
            method : "POST",
            body : JSON.stringify({
              email       : topData.userEmail,
              token       : topData.token,
              Key         : topData.AccessKeyId,
              Secret      : topData.SecretAccessKey,
              nonce       : topData.nonce,
              groupName   : topData.groupName,
              section     : s,
              vsets       : variable_sets,
            })
          })
          .then((response) => {
            if (200 === response.status) {
              s.changed = false;
              response.json().then((data) => {
                if ('top' === s.parent) {
                  s.id           = data.id;
                  s.version      = data.version;
                  s.updated_at   = data.updated_at;
                  updated.push(s); 
                }
                else {
                  list.map((p) => {
                    if (p.id === s.parent) {
                      const children = p.children.reduce((prev, curr) => {
                        if (curr !== s.id) {
                          prev.push(curr);
                        }
                        return prev;
                      }, []);
                      p.children = children;
                      
                      p.changed = true;
                      p.sub_sections.map((ss) => {
                        if (ss.id == s.id) {
                          ss.version = data.version;
                          ss.id      = data.id;
                        }
                      });                    
                    }
                  });
                }
              
                const decrementedList = list.reduce((prev, curr) => {
                  if (curr.id !== s.id) {
                    prev.push(curr);
                  }
                  return prev;
                }, []);
              
                process_next_leaf(decrementedList);
              });
            }
            else {
              response.json().then((data) => {
    
                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }
      
                setTopData(initialState);          
              })            
            }
          });               
        }
        else {
          if ('top' === s.parent) {
            updated.push(s);
          }
          else {
            list.map((p) => {
              if (p.id === s.parent) {
                const children = p.children.reduce((prev, curr) => {
                  if (curr !== s.id) {
                    prev.push(curr);
                  }
                  return prev;
                }, []);
                p.children = children;
              }
            });
          }
        
          const decrementedList = list.reduce((prev, curr) => {
            if (curr.id !== s.id) {
              prev.push(curr);
            }
            return prev;
          }, []);
        
          process_next_leaf(decrementedList);
        }           
      }
      else {
        updated.sort((a,b) => {if (a['order'] > b['order']) { return 1; } else { return -1; }});
                  
        if ('version' === status) {
          setTemplateData({
            ...templateData,
            loading2      : true,
            versionSaving : 'final step, saving overall template structure...',
          });         
        }
        else {
          setTemplateData({
            ...templateData,
            loading3     : true,
            branchSaving : 'final step, saving overall template structure...',
          });         
        }

        fetch(topData.endpoint + "/template", {
          method : "PUT",
          body : JSON.stringify({
            email       : topData.userEmail,
            userId      : topData.userId,
            token       : topData.token,
            template    : {
              sections : updated,
              lines    : templateData.lines,
            },
            id          : templateData.template_id,
            version     : templateData.template_version,
            selected    : templateData.selected,
            draft_id    : templateData.draft_id,
            name        : templateData.newName,
            description : templateData.description,
            status      : status,
            Key         : topData.AccessKeyId,
            Secret      : topData.SecretAccessKey,
            nonce       : topData.nonce,
            groupName   : topData.groupName,
            frontMatter : templateData.frontMatter, 
          })
        })
        .then((response) => {
          if (200 === response.status) {
              response.json().then((data) => { 
                // new -> add new to list
                // version -> update version. 
                
                let templates = templateData.templates;
                let draft_id  = templateData.draft_id;
                let drafts  = templateData.drafts;
              
                if ('new' === status) {
                  templates = [ ...templateData.templates, {
                    id: data.id,
                    version: data.version,
                    name: templateData.newName,
                    description: templateData.description,
                    sections: templateData.template_details
                  } ];
                  
                  draft_id = "";
                }
                else if ('version' === status) {
                  templates = templateData.templates.map((t) => {
                    if (t.id === data.id) {
                      return { ...t, version: data.version, sections: updated, }
                    }
                    else {
                      return t;
                    }
                  });
                  
                  drafts = templateData.drafts.reduce((prev, curr) => {
                    if (curr.draft_id !== draft_id) {
                      prev.push(curr);
                    }
                    
                    return prev;
                  }, []);
                  
                  draft_id = "";
                }
         
                setTemplateData({
                  ...templateData,
                  selected         : [],
                  template_details : [],
                  templates        : templates,
                  draft            : draft_id,
                  drafts           : drafts,
                  footer           : false,
                  updated : {
                    id : templateData.template_id,
                    when : Date.now()
                  },
                  versionSaving : "",
                  branchSaving  : "",
                }); 
        
                setTopData({
                  ...topData,
                  page : 'templates',
                });
              });      
          }
          else {
            response.json().then((data) => {
    
              if (data.message) {
                alert(data.message);
              }
              else {
                alert('Your login has expired'); 
              }
      
              setTopData(initialState);          
            })            
          }
        });                    
      }
    };
        
    process_next_leaf(list);
  };

  const saveNew = (event) => {
    event.preventDefault();
    
    if (!templateData.newName) {
      alert('Please indicate the name for the new Template');
      return false;
    }    
    else {
      setTemplateData({
        ...templateData, 
        loading3 : true,
        branchSaving : 'compiling changes and contacting server...',
      });
      
      process_sections(templateData.template_details, 'new');
    }
  };
  
  const saveVersion = (event) => {
    event.preventDefault();
    
    setTemplateData({
      ...templateData, 
      loading2 : true,
      versionSaving : 'compiling changes and contacting server...',
    });
    
    process_sections(templateData.template_details, 'version');
  };
  
  const saveDraft = (event) => {
    event.preventDefault();
    
    setTemplateData({
      ...templateData,       
      loading1 : true,
    });
    
    fetch(topData.endpoint + "/template", {
      method : "PUT",
      body : JSON.stringify({
        email       : topData.userEmail,
        token       : topData.token,
        template    : {
          sections : templateData.template_details,
          lines    : templateData.lines,
        },
        id          : templateData.template_id,
        draft_id    : templateData.draft_id,
        version     : templateData.template_version,
        status      : 'draft',
        selected    : templateData.selected,
        name        : templateData.newDraftName,
        description : templateData.draftDescription,
        Key         : topData.AccessKeyId,
        Secret      : topData.SecretAccessKey,
        nonce       : topData.nonce,
        groupName   : topData.groupName,
        frontMatter : templateData.frontMatter,
      })
    })
    .then((response) => {
      if (200 === response.status) {
          response.json().then((data) => {  
            setTemplateData({
              ...templateData,
              templates : [],
              loading1 : false,
              footer : false,
              template_details : [],
              sectionHtml : [],
              selected : [],
              template_version : 0,
              template_id : "",
              newSectionNum : 0,
              draft_id : "",
            }); 
            
            setTopData({
              ...topData,
              page : 'templates',
            });
          });      
      }
      else {
        response.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }
          
          setTopData(initialState);          
        })            
      }
    })
  };

  const setValue = (event) => {
    setTemplateData({ ...templateData, [event.target.name]: event.target.value });
  };  
  
  useEffect(() => {
    const description = templateData.templates.reduce((prev,curr) => {
      if (curr.id === templateData.template_id && parseInt(curr.version) === parseInt(templateData.template_version)) {
        return curr.description;
      }
      else {
        return prev;
      }
    }, "");

    setTemplateData({ 
      ...templateData, 
      description: (templateData.draftDescription || description), 
      draftDescription : (templateData.draftDescription || description),
    });
  }, []);

  return (
    <Container className="subPage">
      <Row className="subPageRow">
        <Col md="3">
          <Button 
            className="dataAddInstance saveTempBtn" 
            onClick={saveDraft}
          >
            {
              templateData.loading1 ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />        
              :
              <>Save as Draft</>              
            }
          </Button> 
        </Col>
        <Col md="7">
          <Form.Control 
            type="text" 
            placeholder="New Template Name" 
            name="newDraftName" 
            value={templateData.newDraftName} 
            onChange={setValue} 
            className="dataLiveType saveLive" 
          />
          <Form.Control 
            type="text" 
            placeholder="Description of Template" 
            name="darftDescription" 
            value={templateData.darftDescription} 
            onChange={setValue} 
            className="dataLiveType saveLive" 
          />
          <p className="saveInstructions">
          <span className="draftLabel">Draft:</span> Your edited template will appear as a choice
          for editing when you log in, but will not appear for any other users. 
          </p>
        </Col>
      </Row>
      <Row className="subPageRow">
        <Col md="3">
          <Button 
              className="dataAddInstance saveTempBtn"
              onClick={saveNew}
            >
              {
                templateData.loading3 ? 
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />        
                :
                <>Save as New </>              
              }            
            </Button> 
        </Col>
        <Col md="7">
          <Form.Control 
            type="text" 
            placeholder="New Template Name" 
            name="newName" 
            value={templateData.newName} 
            className="dataLiveType saveLive" 
            onChange={setValue}  
          />
          <Form.Control 
            type="text" 
            placeholder="Description of Template" 
            name="description" 
            value={templateData.description} 
            onChange={setValue}
            className="dataLiveType saveLive"  
          />
          <p className="saveInstructions">
            <span className="draftLabel">
              New:</span> Create a new template.</p>
        </Col>
      </Row>
      <Row className="subPageRow">
        <Col md="3">
          <Button 
            className="dataAddInstance saveTempBtn" 
            onClick={saveVersion}
          >
           {
              templateData.loading2 ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />        
              :
              <>Save as Version</>              
            }            
          </Button> 
          </Col>
          <Col md="7">
          <p className="saveInstructions"><span className="draftLabel">Version:</span> Will save a new version of the existing template. 
          Once saved, this latest version will appear as the default version of this template for any new documents 
          and for anyone wanting to edit this template. The previous version of the template 
          will continue to exist in the database, and any existing documents will continue to 
          use the version of the template they were created from.</p>
          </Col>
        </Row>   
      <Row style={{marginBottom: 35}}>
        <Col></Col>
        <Col>
          <div className="welcomeText">{templateData.versionSaving}</div>
        </Col>  
        <Col>
          <div className="welcomeText">{templateData.branchSaving}</div>
        </Col>        
      </Row> 
    </Container>
  );
}

export default TemplateSaveChoices;