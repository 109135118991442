import { createContext, useState, useContext } from "react";

const DocumentDataStore = createContext();
export const useDocumentDataStore = () => useContext(DocumentDataStore);

export const emptyDocument = {
  documentId      : "",
  variableData   : {},
  variableValues : {},
  buttonLoading  : {},
  version        : 1,
};

export default function DocumentDataStoreProvider({children}) {
  const [documentData, setDocumentData] = useState(emptyDocument);
  
  return (
    <DocumentDataStore.Provider value={{ documentData, setDocumentData }}>
      {children}
    </DocumentDataStore.Provider>
  );
}