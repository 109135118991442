import { createContext, useState, useContext } from "react";

const TopDataStore = createContext();
export const useTopDataStore = () => useContext(TopDataStore);

/*
  https://n3i11u7ok1.execute-api.us-east-1.amazonaws.com/1 = production = app.asclepiagroup.com
  https://8wzmv0fmve.execute-api.us-east-1.amazonaws.com/0 = development = dev.asclepiagroup.com
  https://o4pvasobcc.execute-api.us-east-1.amazonaws.com/qa = qa = qa.asclepiagroup.com
  /TODO: Delete and archive: devin = personal development space https://sk1lbj1lpj.execute-api.us-east-1.amazonaws.com/alpha
  Note: deploy.sh automatically updates the endpoint to the proper environment
*/


export const initialState = {
  token       : "", 
  userEmail   : "", 
  userPassword: "",
  userId      : "",
  confirmCode : "",  
  viewHeight  : window.outerHeight,
  page        : "login",
  footer      : false,
  endpoint    : "https://n3i11u7ok1.execute-api.us-east-1.amazonaws.com/1",
  existing    : [],
  groupName   : "",
  AccessKeyId : "",
  SecretAccessKey : "",
  nonce       : "",
  loginWarning : "",
  lockLogin   : false, 
  lockedEmails : [],   
  companyDocuments : [],
};

export default function TopDataStoreProvider({children}) {
  const [topData, setTopData] = useState(initialState);
  
  return (
    <TopDataStore.Provider value={{ topData, setTopData }}>
      {children}
    </TopDataStore.Provider>
  );
} 