import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import { useVariableDataStore } from "./VariableDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';

function Plus() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
    </svg>
  );
}

function Used() {
  const { variableData, setVariableData } = useVariableDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { topData, setTopData }           = useTopDataStore();
  
  if (!variableData.variables) {
    return (
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />  
    );
  }
  
  const variables = [];
  for (const [k, v] of Object.entries(variableData.variables)) {
    if (v && !v.howDelete) { variables.push(v); }
  }
  variables.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
  
  const edit = (id) => {
    setVariableData({
      ...variableData,
      edit : id,
    });
  };
  
  const deleteVar = (id) => {
    setVariableData({
      ...variableData,
      deleting  : id,
      howDelete : {},
    });
  };
  
  const howDelete = (e) => {
    const m = e.target.id.match(/^\w+/);
    
    setVariableData({
      ...variableData,
      howDelete : { ...variableData.howDelete, id : e.target.value, how : m[0], }
    });
  };
  
  const changeTo = (e) => {
    setVariableData({
      ...variableData,
      howDelete : { ...variableData.howDelete, to : e.target.value, }
    });
  };
  
  const confirmDelete = (id) => {
    if (!variableData.howDelete.how) {
      alert("Please choose what to do with existing instances");
      return false;
    }
    
    if ("change" === variableData.howDelete.how && !variableData.howDelete.to) {
      alert("Select from the dropdown a replacement variable.");
      return false;      
    }
  
    const variables = {};
    const keys      = Object.keys(variableData.variables);
    keys.map((k) => {
      if (id !== k) {
        variables[k] = variableData.variables[k];
      }
    });
    
    const activeVariables = {};
    const keys2      = Object.keys(variableData.activeVariables);
    keys2.map((k) => {
      if (id !== k) {
        activeVariables[k] = variableData.variables[k];
      }
    });
    
    const other = variableData.other.reduce((prev, curr) => {
      if (curr.id !== id) { prev.push(curr); }
      return prev;
    }, []);
    
    fetch(topData.endpoint + '/variable', {
      method : "DELETE",
      body   : JSON.stringify({
        email     : topData.userEmail,
        token     : topData.token,
        Key       : topData.AccessKeyId,
        Secret    : topData.SecretAccessKey,
        nonce     : topData.nonce,
        groupName : topData.groupName,
        howDelete : variableData.howDelete,
      })
    })
    .then((response) => {
      if (200 !== response.status) {
        response.json().then((data) => {
      
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }
        
          setTopData(initialState);          
        })            
      }
    });
    
    setVariableData({
      ...variableData,
      variables       : variables,
      activeVariables : activeVariables,
      other           : other,
      deleting        : "confirmed",
    });
    
    const default_vars = templateData.default_vars.map((vs) => {
      if (vs.id === id) {
        vs.howDelete = variableData.howDelete.how;
        if ("change" === variableData.howDelete.how) {
          vs.changeTo = variableData.howDelete.to;
        }
      }
      
      return vs; 
    });
    
    setTemplateData({
      ...templateData,
      default_vars : default_vars,
    });    
    
    setTopData({
      ...topData,
      deadVars : { ...topData.deadVars, [id] : {
        id        : id,
        howDelete : variableData.howDelete.how,
        changeTo  : variableData.howDelete.to || "",
      }}
    });
  };  
  
  const cancelDelete = () => {
    setVariableData({
      ...variableData,
      deleting : "",
    }) 
  };
  
  const save = (id) => {
    setVariableData({
      ...variableData,
      loading : id,
    });
    // The components are not React managed, because of the lag time in refreshing them. 
    // so we need to find the data. 
    
    const variableSet        = variableData.variables[id];
    variableSet.name         = document.getElementById(`name-${id}`).value;
    variableSet.description  = document.getElementById(`description-${id}`).value;
    variableSet.form_section = document.getElementById(`form_section-${id}`).value;
    variableSet.multiple     = document.getElementById(`multipleYes-${id}`).checked;
    
    variableSet.variables    = variableSet.variables.map((v) => {
      v.variant     = document.getElementById(`variant-${v.id}`).value;
      v.placeholder = document.getElementById(`placeholder-${v.id}`).value;
      v.example     = document.getElementById(`example-${v.id}`).value;
      v.select      = document.getElementById(`selectYes-${v.id}`).checked;
      const options = document.getElementById(`options-${v.id}`).value.trim();
      if (options) {
        const parts = options.split(',');
        v.options   = parts.map((p) => {
          return p.trim();
        });
      }
      else {
        v.options = [];
      }
      return v;
    });
  
    fetch(topData.endpoint + '/variable', {
      method : "PUT",
      body : JSON.stringify({
        email     : topData.userEmail,
        token     : topData.token,
        vset      : variableSet,
        Key       : topData.AccessKeyId,
        Secret    : topData.SecretAccessKey,
        nonce     : topData.nonce,
        groupName : topData.groupName,
      })
    })
    .then((response) => {
      if (200 === response.status) {
        response.json().then((data) => {
          
          if (id.match(/^newVariableSet/)) {
            const newVariables = {};
            for (const [k, v] of Object.entries(variableData.variables)) {
              if (k === id) {
                newVariables[data.vset.id] = data.vset;
              }
              else {
                newVariables[k] = v;
              }
            }
          
            setVariableData({
              ...variableData,
              edit      : "",
              loading   : "",
              loading1  : false,
              variables : newVariables,    
            });
          }
          else {
            setVariableData({
              ...variableData,
              edit    : "",
              loading : "",
              variables : { 
                ...variableData.variables,         
                [id]    : data.vset,
              },
            });

            const updateVariable = (sections) => {
              return sections.map((s) => {
                if (s.variable_sets) {
                  s.variable_sets = s.variable_sets.map((vset) => {
                    if (vset.id === data.vset.id) {
                      return data.vset;
                    }
                    else {
                      return vset;
                    }
                  });
 
                  if (data.addMultiple && s.html) {
                    data.vset.variables.map((v) => {
                      const str = 'tag="' + data.vset.id + '\.' + v.id + '"';
                      const now = 'tag="' + data.vset.id + '.' + v.id + '.1"';
                      const re  = new RegExp(str, "g");
                      s.html    = s.html.replace(re, now);
                    });
                  }
                  else if (data.removeMultiple && s.html) {
                    data.vset.variables.map((v) => {
                      const str = 'tag="' + data.vset.id + '\.' + v.id + '\.\d+"';
                      const now = 'tag="' + data.vset.id + '.' + v.id + '"';
                      const re  = new RegExp(str, "g");
                      s.html    = s.html.replace(re, now)
                    });
                  }
                }
                            
                if (s.sub_sections) {
                  s.sub_sections = updateVariable(s.sub_sections);
                }
              
                return s;
              });
            };
          
            const updated = updateVariable(templateData.template_details);
          
            setTemplateData({
              ...templateData,
              template_details : updated,
            });

            fetch(topData.endpoint + "/template", {
              method : "PUT",
              body : JSON.stringify({
                email       : topData.userEmail,
                token       : topData.token,
                template    : {
                  sections : updated,
                  lines    : templateData.lines,
                },
                id          : templateData.template_id,
                version     : templateData.template_version,
                draft_id    : templateData.draft_id,
                status      : 'draft',
                selected    : templateData.selected,
                Key         : topData.AccessKeyId,
                Secret      : topData.SecretAccessKey,
                nonce       : topData.nonce,
                groupName   : topData.groupName,
                frontMatter : templateData.frontMatter,
              })
            })
            .then((response) => {
              if (200 === response.status) {
                response.json().then((data) => {
                  if (data.draft_id) {
                    setTemplateData({
                      ...templateData,
                      template_details : updated,
                      draft_id : data.draft_id,
                      dirty    : false,
                    });
                  }
                });        
              }
              else {
                response.json().then((data) => {
        
                  if (data.message) {
                    alert(data.message);
                  }
                  else {
                    alert('Your login has expired'); 
                  }
          
                  setTopData(initialState);          
                })            
              }
            });
          
          }
        });
      }
      else {
        response.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }
          
          setTopData(initialState);          
        })            
      }
    })
  };
  
  const newVariant = (vsetId) => {
    const num = parseInt(variableData.newVariants) + 1;
    const newVariant = {
        "id": `newVariant-${num}`,
        "version": 1,
        "variant": "",
        "placeholder": "#" + variableData.variables[vsetId].name.toUpperCase() + '.newVariant',
        "select": false,
        "options": [],
        "example": "",
    };
    
    const updated = variableData.variables[vsetId].variables || [];
    updated.push(newVariant);
            
    setVariableData({
      ...variableData,
      variables : { 
        ...variableData.variables,         
        [vsetId] : { 
              ...variableData.variables[vsetId], 
              variables: updated,
             }
      },
      newVariants : num,
      edit        : vsetId,
    });
    
    setTemplateData({
      ...templateData,
      dirty : true,
    });
  };
  
  const removeVariant = (vsetId, variantId) => {
    const remaining = variableData.variables[vsetId].variables.reduce((prev, curr) => {
      if (curr.id !== variantId) {
        prev.push(curr);
      }
      return prev;
    }, []);
    
    setVariableData({
      ...variableData,
      variables : { 
        ...variableData.variables,         
        [vsetId] : { 
              ...variableData.variables[vsetId], 
              variables: remaining,
            }
      },
    });
  };
  
  const variantDetails = (variants, setID) => {
    return variants.map((v) => {
      if (v) {
        return (
          <Table bordered 
              hover 
              className="tbl varT"
              key={setID + '.' + v.id}>
            <tbody className="tblBody varBody">
              <tr className="varR tblR">
                <th className="varH tblH">Variant Name</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === setID) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={v.variant || 'default'}
                      className="dataLiveType tblLiveType" 
                      id={'variant-'+v.id} 
                    />
                    : <>{v.variant || 'default'}</>
                  }              
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Placeholder</th>
                <td>
                  {
                    (variableData.edit && variableData.edit === setID) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={v.placeholder} 
                      id={'placeholder-'+v.id} 
                      className="dataLiveType tblLiveType"
                    />
                    : <>{v.placeholder}</>
                  }              
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Example</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === setID) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={v.example} 
                      id={'example-'+v.id} 
                      className="dataLiveType tblLiveType"
                    />
                    : <>{v.example}</>
                  }              
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Use Dropdown Select List?</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === setID) ?
                      <div className="mb-3">                
                        <Form.Check
                          inline
                          label="yes"
                          name={`select-${v.id}`}
                          type="radio"
                          id={`selectYes-${v.id}`} 
                          defaultChecked={v.select}                       
                        />
                        <Form.Check
                          inline
                          label="no"
                          name={`select-${v.id}`}
                          type="radio"
                          id={`selectNo-${v.id}`}
                          defaultChecked={!v.select}
                        />
                      </div>
                      : <>{v.select ? 'yes' : 'no'}</>
                  }              
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Options List (if dropdown)</th>
                <td>
                  {
                    (variableData.edit && variableData.edit === setID) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={v.options ? v.options.join(', ') : ""} 
                      id={'options-'+v.id} 
                      placeholder="option1, option2, option3..." 
                      className="dataLiveType tblLiveType"
                    />
                    : <>{v.options ? v.options.join(', ') : ""}</>
                  }
                </td>
              </tr>
              {
                (variants.indexOf(v)) ?
                <Button 
                      className="dataAddInstance varTempBtn warnBtn" 
                      onClick={() => removeVariant(setID, v.id)}
                    >
                        Remove Variant
                </Button>
                : <></>              
              }
            </tbody>
          </Table>
        );      
      }
      else {
        return "";
      }
    });
  };
  
  return variables.map((vset) => {
      return (<Accordion.Item key={vset.id} eventKey={vset.id}>
        <Accordion.Header className="acchHead">
          {vset.name}
        </Accordion.Header>
        { (variableData.deleting && variableData.deleting === vset.id) ?
        <Accordion.Body className="accBody">
          <p className="saveInstructions varInstrux">
          To delete this variable, choose an action to take with any existing instances
          of this variable in templates and documents, then click the "confirm" button. 
          Cancelling this action will allow you to continue editing or using this variable.
          </p>
          <Row className="varRow">
            <Col xs={5}>
              <Form.Check
                type={'radio'}
                name='deleteAction'
                value={vset.id}
                label={'Instances are converted to the selected variable.'}
                id={`change-${vset.id}`}
                onChange={howDelete}
                className="deleteCheck"
              />      
            </Col>
            <Col xs={7}>
              <Form.Select name="changeTo" id={'changeTo'} onChange={changeTo}>
                <option value="" key="null">(choose from list)</option>
                {
                  variables.map((vs) => {
                    if (vs.id !== vset.id) {
                      return (
                        <option value={vs.id} key={vs.id}>{vs.name}</option>
                      );
                    }
                  }) 
                }
              </Form.Select>
            </Col>
          </Row>
          <Row className="varRow">
            <Col>
              <Form.Check
                type={'radio'}
                name='deleteAction'
                value={vset.id}
                label={'Existing values become plain text.'}
                id={`text-${vset.id}`}
                onChange={howDelete}
                className="deleteCheck"
              />      
            </Col>
          </Row>  
          <Row className="rowBtn">
              <Button 
                className="dataAddInstance varTempBtn warnBtn" 
                onClick={() => confirmDelete(vset.id)}>
                  Confirm Delete
              </Button>
              <Button              
                className="dataAddInstance varTempBtn warnBtn" 
                onClick={cancelDelete}>
                  Cancel
              </Button>
          </Row>
        </Accordion.Body>
        :
        <Accordion.Body className="accBody">
          {
            (variableData.edit && variableData.edit === vset.id) ?
              <Button 
                className="dataAddInstance varTempBtn" 
                onClick={() => save(vset.id)}>
                  {
                    (variableData.loading && variableData.loading === vset.id) ?
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />  
                    : <>Save</>                    
                  }
              </Button>
            :
              <Button 
                  className="dataAddInstance varTempBtn"
                  onClick={() => edit(vset.id)}>
                  Edit
              </Button>
          }
          <Button 
            className="dataAddInstance varTempBtn"
            onClick={() => deleteVar(vset.id)}>
              Delete
          </Button>
          <Table 
              className="tbl varT"
              bordered 
              hover 
              key={vset.id}>
            <tbody className="varBody tblBody">
              <tr className="varR tblR">
                <th className="varH tblH">
                  Name
                </th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === vset.id) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={vset.name} 
                      id={'name-'+vset.id} 
                    />
                    : <>{vset.name}</>
                  }
                </td>
              </tr>
              <tr className="var tbl">
                <th className="varH tblH">Version</th>
                <td>{vset.version}</td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Description</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === vset.id) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={vset.description} 
                      id={'description-'+vset.id}  
                      className="dataLiveType tblLiveType"
                    />
                    : <>{vset.description}</>
                  }                
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Section of Form</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === vset.id) ?
                    <Form.Select name="form_section" id={'form_section-'+vset.id} defaultValue={vset.form_section}>
                      <option value="Study Information">Study Information</option>
                      <option value="Objectives">Objectives</option>
                      <option value="Study Design">Study Design</option>
                      <option value="Patient Population">Patient Population</option>
                      <option value="Variables">Variables</option>
                    </Form.Select>
                    : <>{vset.form_section}</>
                  }
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Allow Multiple Instances?</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === vset.id) ?  
                    <div className="mb-3">                
                      <Form.Check
                        inline
                        label="yes"
                        name="multiple"
                        type="radio"
                        id={`multipleYes-${vset.id}`} 
                        defaultChecked={vset.multiple}                       
                      />

                      <Form.Check
                        inline
                        label="no"
                        name="multiple"
                        type="radio"
                        id={`multipleNo-${vset.id}`}
                        defaultChecked={!vset.multiple}
                      />
                    </div>
                    : <>{vset.multiple ? 'yes' : 'no'}</>
                  }                
                </td>
              </tr>
            </tbody>
          </Table>
          <h4 className="pageLevel2Head varHead">
            Variants         
          </h4>
          <div className="tempVarBtn">
          <Button 
                className="dataAddInstance varTempBtn variant" 
            onClick={() => newVariant(vset.id)}
          >
            Add Variant
          </Button>
          </div>
          {variantDetails(vset.variables, vset.id)}
        </Accordion.Body>
      }
      </Accordion.Item>);
  });
  
}

function Other() {
  const { variableData, setVariableData } = useVariableDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { topData, setTopData }           = useTopDataStore();

  const otherVariables = variableData.other.reduce((prev, curr) => {
    if (!curr.howDelete) { prev.push(curr); }
    return prev;
  }, []);
  
  if (otherVariables) {
    otherVariables.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    
    const edit = (id) => {
      setVariableData({
        ...variableData,
        edit : id,
      });
    };

    const deleteVar = (id) => {
      setVariableData({
        ...variableData,
        deleting : id,
      });
    };  
    
    const activate = (id) => {
      const variableSet    = variableData.other.reduce((prev, curr) => {
        if (curr.id === id) {
          return curr;
        }
        else {
          return prev;
        }
      }, {});

      setVariableData({
        ...variableData,
        edit    : "",
        loading : "",
        variables : { 
          ...variableData.variables,         
          [id]    : variableSet,
        },
        other   : variableData.other.reduce((prev, curr) => {
          if (curr.id !== id) { prev.push(curr); }
          return prev;
        }, [])
      });
      
    };
    
    const save = (id) => {
      setVariableData({
        ...variableData,
        loading : id,
      });
    
      const variableSet        = variableData.other.reduce((prev, curr) => {
        if (curr.id === id) {
          return curr;
        }
        else {
          return prev;
        }
      }, {});

      variableSet.name         = document.getElementById(`name-${id}`).value;
      variableSet.description  = document.getElementById(`description-${id}`).value;
      variableSet.form_section = document.getElementById(`form_section-${id}`).value;
      variableSet.multiple     = document.getElementById(`multipleYes-${id}`).checked;

      variableSet.variables    = variableSet.variables.map((v) => {
        v.variant     = document.getElementById(`variant-${v.id}`).value;
        v.placeholder = document.getElementById(`placeholder-${v.id}`).value;
        v.example     = document.getElementById(`example-${v.id}`).value;
        v.select      = document.getElementById(`selectYes-${v.id}`).checked;
        const options = document.getElementById(`options-${v.id}`).value.trim();
        if (options) {
          const parts = options.split(',');
          v.options   = parts.map((p) => {
            return p.trim();
          });
        }
        else {
          v.options = [];
        }
        return v;
      });

      fetch(topData.endpoint + '/variable', {
        method : "PUT",
        body : JSON.stringify({
          email     : topData.userEmail,
          token     : topData.token,
          vset      : variableSet,
          Key       : topData.AccessKeyId,
          Secret    : topData.SecretAccessKey,
          nonce     : topData.nonce,
          groupName : topData.groupName,
        })
      })
      .then((response) => {
        if (200 === response.status) {          
          response.json().then((data) => {
            // will have new versions set as appropriate by the backend.             
            setVariableData({
              ...variableData,
              edit    : "",
              loading : "",
              variables : { 
                ...variableData.variables,         
                [id]    : data.vset,
              },
              other   : variableData.other.reduce((prev, curr) => {
                if (curr.id !== id) { prev.push(curr); }
                return prev;
              }, [])
            });
          });
        }
        else {
          response.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
          
            setTopData(initialState);          
          })            
        }
      });      
    };
    
    const removeVariant = (vsetId, variantId) => {
      const updated = variableData.other.map((o) => {
        if (o.id === vsetId) {
          o.variables = o.variables.reduce((prev, curr) => {
            if (curr.id !== variantId) {
              prev.push(curr);
            }
            return prev;
          }, []);
        }
        return o;
      });

      setVariableData({
        ...variableData,
        other : updated,
      });
    
      setTemplateData({
        ...templateData,
        dirty : true,
      });
    
    };
    
    const newVariant = (vsetId, name) => {
      const num = parseInt(variableData.newVariants) + 1;
      const newVariant = {
          "id": `newVariant-${num}`,
          "version": 1,
          "variant": "",
          "placeholder": `#${name.toUpperCase()}.newVariant`,
          "select": false,
          "options": [],
          "example": "",
      };
    
      const updated = variableData.other.map((o) => {
        if (o.id === vsetId) {
          o.variables.push(newVariant);
        }
        return o;
      });
            
      setVariableData({
        ...variableData,
        other       : updated,
        newVariants : num,
        edit        : vsetId,
      });
    
      setTemplateData({
        ...templateData,
        dirty : true,
      });
    };    

    const variantDetails = (variants, setID) => {
      return variants.map((v) => {
        if (v) {
          return (
            <Table 
              bordered 
              hover 
              className="tbl varT"
              key={setID + '.' + v.id}
            >
              <tbody className="tblBody varBody">
                <tr className="varR tblR">
                  <th className="varH tblH">Variant Name</th>
                  <td className="varD tblD">
                    {
                      (variableData.edit && variableData.edit === setID) ?
                      <Form.Control 
                        type="text" 
                        defaultValue={v.variant || 'default'} 
                        id={'variant-'+v.id}  
                        className="dataLiveType tblLiveType"
                      />
                      : <>{v.variant || 'default'}</>
                    }              
                  </td>
                </tr>
                <tr className="varR tblR">
                  <th className="varH tblH">Placeholder</th>
                  <td className="varD tblD">
                    {
                      (variableData.edit && variableData.edit === setID) ?
                      <Form.Control 
                        type="text" 
                        defaultValue={v.placeholder} 
                        id={'placeholder-'+v.id} 
                      />
                      : <>{v.placeholder}</>
                    }              
                  </td>
                </tr>
                <tr className="varR tblR">
                  <th className="varH tblH">Example</th>
                  <td className="varD tblD">
                    {
                      (variableData.edit && variableData.edit === setID) ?
                      <Form.Control 
                        type="text" 
                        defaultValue={v.example} 
                        id={'example-'+v.id}  
                        className="dataLiveType tblLiveType"
                      />
                      : <>{v.example}</>
                    }              
                  </td>
                </tr>
                <tr className="varR tblR">
                  <th className="varH tblH">Use Dropdown Select List?</th>
                  <td className="varD tblD">
                    {
                      (variableData.edit && variableData.edit === setID) ?
                        <div className="mb-3">                
                          <Form.Check
                            inline
                            label="yes"
                            name={`select-${v.id}`}
                            type="radio"
                            id={`selectYes-${v.id}`} 
                            defaultChecked={v.select} 
                            className="radioBoxes"                      
                          />

                          <Form.Check
                            inline
                            label="no"
                            name={`select-${v.id}`}
                            type="radio"
                            id={`selectNo-${v.id}`}
                            defaultChecked={!v.select} 
                            className="radioBoxes"  
                          />
                        </div>
                        : <>{v.select ? 'yes' : 'no'}</>
                    }              
                  </td>
                </tr>
                <tr className="varR tblR">
                  <th className="varH tblH">Options List (if dropdown)</th>
                  <td className="varD tblD">
                    {
                      (variableData.edit && variableData.edit === setID) ?
                      <Form.Control 
                        type="text" 
                        defaultValue={v.options ? v.options.join(', ') : ""} 
                        id={'options-'+v.id} 
                        placeholder="option1, option2, option3..."  
                        className="dataLiveType tblLiveType"
                      />
                      : <>{v.options ? v.options.join(', ') : ""}</>
                    }
                  </td>
                </tr>
                {
                  (variants.indexOf(v)) ?
                  <tr className="varR tblR">
                    <td className="varD tblD">
                      <Button 
                        className="dataAddInstance varTempBtn warnBtn" 
                        onClick={() => removeVariant(setID, v.id)}>
                          Remove Variant
                      </Button>
                    </td>
                    <td className="varD tblD">
                    </td>
                  </tr>
                  : <></>              
                }
              </tbody>
            </Table>
          );      
        }
        else {
          return "";
        }
      });
    };

    const items = otherVariables.map((vset) => {
        return (<Accordion.Item key={vset.id} eventKey={vset.id}>
          <Accordion.Header className="accHead">
            {vset.name}
          </Accordion.Header>
          { (variableData.deleting && variableData.deleting === vset.id) ?
          <Accordion.Body className="accBody">
            <p>
            To delete this variable, choose an action to take with any existing instances
            of this variable in templates and documents, then click the "confirm" button. 
            Cancelling this action will allow you to continue editing or using this variable.
            </p>
            <Form.Check
                disabled
                type={'radio'}
                name='deleteAction'
                value={vset.id}
                label={'Instances are converted to the selected variable.'}
                id={`change-${vset.id}`}
                className="deleteCheck"
              />          

          </Accordion.Body>
          :
          <Accordion.Body className="accBody">
            {
              (variableData.edit && variableData.edit === vset.id) ?
                <Button 
                  className="dataAddInstance varTempBtn "  
                  onClick={() => save(vset.id)}>
                    {
                      (variableData.loading && variableData.loading === vset.id) ?
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />  
                      : <>Save</>                    
                    }
                </Button>
              :
                <div>
                  <Button 
                    className="dataAddInstance varTempBtn warnBtn rowBtn" 
                    onClick={() => edit(vset.id)}>
                      Edit
                  </Button>
                  <Button 
                    className="dataAddInstance varTempBtn rowBtn"
                    onClick={() => activate(vset.id)}>
                    Activate
                  </Button>

                  <Button 
                    className="dataAddInstance varTempBtn rowBtn" 
                    onClick={() => deleteVar(vset.id)}>
                      Delete
                  </Button>
                </div>
            }
            <Table 
              className="tbl varT"
              bordered 
              hover 
              key={vset.id}
            >
              <tbody className="tblBody varBody"y>
                <tr className="varR tblR">
                  <th className="varH tblH">
                    Name
                  </th>
                  <td className="varD tblD">
                    {
                      (variableData.edit && variableData.edit === vset.id) ?
                      <Form.Control 
                        type="text" 
                        defaultValue={vset.name} 
                        id={'name-'+vset.id}  
                        className="dataLiveType tblLiveType"
                      />
                      : <>{vset.name}</>
                    }
                  </td>
                </tr>
                <tr className="varR tblR">
                  <th className="varH tblH">Version</th>
                  <td className="varD tblD">{vset.version}</td>
                </tr>
                <tr className="varR tblR">
                  <th className="varH tblH">Description</th>
                  <td className="varD tblD">
                    {
                      (variableData.edit && variableData.edit === vset.id) ?
                      <Form.Control 
                        type="text" 
                        defaultValue={vset.description} 
                        id={'description-'+vset.id}  
                        className="dataLiveType tblLiveType"
                      />
                      : <>{vset.description}</>
                    }                
                  </td>
                </tr>
                <tr className="varR tblR">
                  <th className="varH tblH">Section of Form</th>
                  <td className="varD tblD">
                    {
                      (variableData.edit && variableData.edit === vset.id) ?
                      <Form.Select name="form_section" id={'form_section-'+vset.id} defaultValue={vset.form_section}>
                        <option value="Study Information">Study Information</option>
                        <option value="Objectives">Objectives</option>
                        <option value="Study Design">Study Design</option>
                        <option value="Patient Population">Patient Population</option>
                        <option value="Variables">Variables</option>
                      </Form.Select>
                      : <>{vset.form_section}</>
                    }
                  </td>
                </tr>
                <tr className="varR tblR">
                  <th className="varH tblH">Allow Multiple Instances?</th>
                  <td className="varD tblD">
                    {
                      (variableData.edit && variableData.edit === vset.id) ?  
                      <div className="mb-3">                
                        <Form.Check
                          inline
                          label="yes"
                          name="multiple"
                          type="radio"
                          id={`multipleYes-${vset.id}`} 
                          defaultChecked={vset.multiple}                       
                        />

                        <Form.Check
                          inline
                          label="no"
                          name="multiple"
                          type="radio"
                          id={`multipleNo-${vset.id}`}
                          defaultChecked={!vset.multiple}
                        />
                      </div>
                      : <>{vset.multiple ? 'yes' : 'no'}</>
                    }                
                  </td>
                </tr>
              </tbody>
            </Table>
        
            <h4 className="pageLevel2Head varHead">
              Variants
            </h4>
          <div className="tempVarBtn">
            <Button 
              className="dataAddInstance varTempBtn variant" 
              onClick={() => newVariant(vset.id, vset.name)}
            >
              Add Variant
            </Button> 
            </div>
            {variantDetails(vset.variables, vset.id)}
          </Accordion.Body>
        }      
        </Accordion.Item>);
    });
    return (<Accordion className="otherVariables">{items}</Accordion>);
  }
  else {
    return (
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />    
    );
  }
}

function Variables() {
  const { topData, setTopData }           = useTopDataStore();
  const { variableData, setVariableData } = useVariableDataStore();

  let dataFetching = false;
  useEffect(() => {
    if (!dataFetching && (!variableData.other || !variableData.other.length)) {
      dataFetching = true;
      window.scrollTo(0, 0);   
      
      const setIds = [];
      for (const [k, v] of Object.entries(variableData.variables)) {
        setIds.push(k);
      }
      
      const existing = btoa(JSON.stringify(setIds)).replace(/\//g, '_').replace(/\+/g, '-');            

      const url = topData.endpoint + "/variable?email=" + topData.userEmail + '&e=' + existing + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
      fetch(url, {
        method : "GET",
        cache : "no-cache",
        headers : {
          Authorization : topData.token,
        }      
      })
      .then((res) => {
        if (200 === res.status) {
          res.json().then((returned) => {
            setVariableData({
              ...variableData,
              other     : returned.data,
            });    
          });
        }
        else {
          res.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
          
            setTopData(initialState);          
          });      
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });      
    }  
  }, []);
  
  const goBack = (e) => {
    e.preventDefault();
        
    setTopData({
      ...topData,
      page: "templateText",
    });
    
    
  };
  
  const newVariable = (e) => {
    e.preventDefault();
    
    const num  = parseInt(variableData.newVariableSets) + 1;
    const num1 = parseInt(variableData.newVariants) + 1;
    const newVariableSet = {
      id : `newVariableSet-${num}`,
      name : '_NEW_VARIABLE',
      description : '',
      version : 1,
      form_section : "Study Information",
      multiple : false, 
      variables : [{
        id : `newVariant-${num1}`,
        version : 1,
        variant : '',
        placeholder : '#_NEW_VARIABLE.newVariant',
        select : false,
        options : [],
        example: ''
      }]
    }
        
    setVariableData({
      ...variableData,
      variables : { ...variableData.variables, [`newVariableSet-${num}`] : newVariableSet, },
      edit      : `newVariableSet-${num}`,
    });    
  };

  return (
    <Container className="subPage">
      <Row className="subPageRow" >
        <p className="docLinkItem" 
          id="newSection">
        <a href="#new" onClick={newVariable}  >
          <Plus />Add New Variable
        </a></p>
      </Row>
      <Accordion><Used /></Accordion>
      <Other />
    </Container>
  );
  
}

export default Variables;