import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
 
function Sections() {
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { topData, setTopData }           = useTopDataStore();
  
  const include_subsections = (s, included) => {
    s.sub_sections.map((ss) => {
      if (ss && ss.id) {included.push(ss.id)}
      if (ss && ss.sub_sections) {
        include_subsections(ss, included);
      }
    });
  };
  
  const find_subsection = (subsections, id, included) => {
    subsections.map((s) => {
      if (s && id && s.id && id === s.id) {
        included.push(id);
        if (s.sub_sections) {
          include_subsections(s, included)
        }
      }
      else if (s && s.sub_sections) {
        find_subsection(s.sub_sections, id, included);
      }
    });
  };
  
  const recurse_count = (sections, newSections) => {
    let count = 0;
    sections.map((s) => {
      if (s.id) {
        if (templateData.selected.includes(s.id)) {
          count++;
        }
        if (s.sub_sections) {
          const sub = [];
          recurse_count(s.sub_sections, sub);
          s.sub_sections = sub;
        }        
      }      
    });
    
    sections.map((s) => {
      if (s.id) {
        s.count = count;
        newSections.push(s);     
      }
    });
  };
  
  useEffect(() => {
    if (templateData.template_details && templateData.template_details.length) {
      templateData.template_details.map((s) => {
        if (s.sub_sections) {
          const sub = []
          find_subsection(templateData.template_details, s.id, sub);
          let on = false;
          sub.map((ssid) => {
            if (ssid && templateData.selected.includes(ssid) && ssid !== s.id) { on = true };
          });
          if (on && !templateData.selected.includes(s.id)) {
            setTemplateData({
              ...templateData,
              selected : [...templateData.selected, s.id], 
            });
          }
          else if (!on && templateData.selected.includes(s.id)) {
            const newList = templateData.selected.reduce((prev, curr) => {
              if (!curr === s.id && curr) {
                prev.push(curr);
              }
              return prev;
            }, []);
            
            setTemplateData({
              ...templateData,
              selected : newList,
            });
          }
        }
      });    
      
      let count = 0;
      templateData.template_details.map((s) => {
        if (templateData.selected.includes(s.id)) {
          count++;
        }
      });
      const newDetails = [];
      templateData.template_details.map((s) => {
        s.count = count;
        
        if (s.sub_sections) {
          const sub = [];
          recurse_count(s.sub_sections, sub);
          s.sub_sections = sub;
        }
        
        newDetails.push(s);
      });
      setTemplateData({
        ...templateData,
        template_details : newDetails
      });
    }    
  }, [templateData.selected]);
   
  const selectUnselect = (e) => {
    const id = e.target.name;

    // recursively find all id's to check or uncheck.
    const included = [];
    find_subsection(templateData.template_details, id, included);
        
    if (templateData.selected.includes(id)) {
      const newList = templateData.selected.reduce((prev, curr) => {
        if (curr && !included.includes(curr)) {
          prev.push(curr);
        }
        return prev;
      }, []);
            
      setTemplateData({
        ...templateData,
        selected : newList,
      });
    }
    else {
      setTemplateData({
        ...templateData,
        selected : [...templateData.selected, ...included], 
      });
    }
  };
      
  const recursiveSubSections = (prefix, sectionList, runningOutputList, showUnchecked) => {
    let thisSublevel = 1;
    sectionList.map((s) => {
      if (s && s.id && s.name) {
        const labelName = String(prefix) + "." + String(thisSublevel) + ". " + s.name;
        if (showUnchecked || templateData.selected.includes(s.id)) {
          runningOutputList.push(
            <div key={s.id + Math.floor(Math.random() * 100)} style={{marginBottom:5}}>
              <Form.Check className="assembleForm"
                inline
                label={labelName}
                name={s.id}
                type="checkbox"
                checked={templateData.selected.includes(s.id)}
                onChange={selectUnselect}
              />
            </div>
          );              
        }

        if (s.sub_sections) {
          const addToList = recursiveSubSections(String(prefix)+"."+String(thisSublevel), s.sub_sections, runningOutputList, showUnchecked);
          addToList.map((s) => {
            sectionList.push(s);
          });
        }
      }      
      
      thisSublevel++;
      
    });
    return runningOutputList;
  };
  
  const column = (showUnchecked) => {
    const outof = showUnchecked ? 2 : 1;
    
    const sectionList = [];
    for (let i=0; i<(templateData.template_details.length); i++) {
      const thisSection = templateData.template_details[i];
      if (thisSection && thisSection.id && thisSection.name) {
        const labelName = String((i+1)) + ". " + templateData.template_details[i].name;
        if (showUnchecked || templateData.selected.includes(templateData.template_details[i].id)) {
          sectionList.push(
              <Accordion.Item 
                  eventKey={templateData.template_details[i].id + String(outof)} 
                  key={templateData.template_details[i].id + String(outof)}
              >
                <Accordion.Header>
                  <Form.Check className="assembleForm"
                    inline
                    label={labelName}
                    name={templateData.template_details[i].id}
                    type="checkbox"
                    checked={templateData.selected.includes(templateData.template_details[i].id)}
                    onChange={selectUnselect}
                  />
                </Accordion.Header>
                {
                  thisSection.sub_sections ?
                    <Accordion.Body>
                      {recursiveSubSections((i+1), thisSection.sub_sections, [], showUnchecked)}
                    </Accordion.Body>                  
                  : ""
                }
              </Accordion.Item>
          );              
        }
        
      }
    }
    
    return sectionList;
  };

  if (templateData.template_details && templateData.template_details.length) {    
    return (
      <>
        <Row>
          <Col xs={8}>
            <Accordion className="parentSections">
              {column(true)}
            </Accordion>
          </Col>
          <Col xs={4}>
            <Accordion>
              {column(false)}
            </Accordion>
          </Col>
        </Row>
      </>
    );
  }
  else {
    return (
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />                        
    );
  }
}

function FrontMatterButtons() {
  const { topData, setTopData }           = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();

  const gotoTitlePage = (event) => {
    setTopData({
      ...topData,
      page: "templateTitlePage",
    });
  };
  
  if ("undefined" === typeof(templateData.lines)) {
    return (
      <Col>
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
        />                        
      </Col>
    );
  }
  else {
    const fmList = templateData.frontMatter || templateData.frontMatterOptions;
    return fmList.map((fm) => {
      if ("titlePage" === fm) {
        return (<Col key={fm}>
          <Button 
            variant="primary" 
            className="intNavButton"
            onClick={gotoTitlePage}>
              Configure Title Page
          </Button>
        </Col>);
      }
    });  
  }
}

function TemplateSetup() {
  const { topData, setTopData }           = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  
  const addSection = () => {
    
    const num = templateData.newSectionNum + 1;
    
    const sel = [ ...templateData.selected, `newSection-${num}`, ];

    const recurse_count = (sections, newSections) => {
      let count = 0;
      sections.map((s) => {
        if (s.id) {
          if (sel.includes(s.id)) {
            count++;
          }
          if (s.sub_sections) {
            const sub = [];
            recurse_count(s.sub_sections, sub);
            s.sub_sections = sub;
          }        
        }      
      });
    
      sections.map((s) => {
        if (s.id) {
          s.count = count;
          newSections.push(s);     
        }
      });
    };
    
    const det = [ {
        id : `newSection-${num}`,
        version : 1,
        name : 'Newly Added Section',
        sub_sections : [],
        count : 0,
        html : "",
        guidance : "",
      }, ...templateData.template_details, ];
      
    let count = 0;
    det.map((s) => {
      if (sel.includes(s.id)) {
        count++;
      }
    });
    const newDetails = [];
    det.map((s) => {
      s.count = count;
      
      if (s.sub_sections) {
        const sub = [];
        recurse_count(s.sub_sections, sub);
        s.sub_sections = sub;
      }
      
      newDetails.push(s);
    });
    
    
    setTemplateData({
      ...templateData,
      template_details : newDetails,
      newSectionNum    : num,
      selected : sel,
      frontMatter : templateData.frontMatter || templateData.frontMatterOptions,
      lines : templateData.lines || [],
    });
  };
   
  let dataFetching = false;
  useEffect(() => {
    if (!dataFetching && (!templateData.selected || !templateData.selected.length)) {
      dataFetching = true;
      window.scrollTo(0, 0);  
      
      const frontMatterOptions = ['titlePage', 'tableOfContents', 'synopsis', 'versions', 'abbreviations', 
      'schematic', 'signaturePages', 'complianceStatement', 'scheduleOfEvents'];
      
      if (templateData.template_id || templateData.draft_id) {
        const url = topData.endpoint + "/template?email=" + topData.userEmail + "&template_id=" + templateData.template_id + "&draft=" + templateData.draft_id + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName; 
        fetch(url, {
          method : "GET",
          cache : "no-cache",
          headers : {
            Authorization : topData.token,
          }      
        })
        .then((res) => {
          if (200 === res.status) {
            res.json().then((data) => { 
              const templateName = templateData.templates.reduce((prev, curr) => {
                if (curr.id === templateData.template_id) {
                  return curr.name;
                }
                else {
                  return prev;
                }
              }, '');
              
              data.frontMatter = data.frontMatter.length ? data.frontMatter : frontMatterOptions;
            
              if (data.details.selected && data.details.selected.length) {              
                if (templateData.updated && templateData.updated.id === templateData.template_id
                && (templateData.updated.when + 20000) > Date.now()) {
                  // user just submitted a save to this template and that save will not yet 
                  // be complete on the backend, so use what we already have. 
                  const details = templateData.templates.reduce((prev, curr) => {
                    if (curr.id === templateData.template_id) {
                      return curr.sections;
                    }
                    else {
                      return prev;
                    }
                  }, []);
                  
                  setTemplateData({
                    ...templateData,
                    template_details : details,
                    selected : data.details.selected,
                    collapsed : [],
                    frontMatter : data.frontMatter,
                    lines : data.lines,
                    name : templateName,
                    frontMatterOptions : frontMatterOptions,
                  });                       
                }
                else {
                  setTemplateData({
                    ...templateData,
                    template_details : data.details.content,
                    selected : data.details.selected,
                    collapsed : [],
                    frontMatter : data.frontMatter,
                    lines : data.lines,
                    name : templateName,
                    frontMatterOptions : frontMatterOptions,
                  });                     
                }
                
                dataFetching = false;                     
              }
              else {
                const collection = [];
                const allSectionIds = (sections) => {
                  return sections.map((s) => {
                    if (s.id) { 
                      if (!collection.includes(s.id)) {
                        collection.push(s.id);                       
                      }
                    }
                    
                    if (s.sub_sections && s.sub_sections.length) {
                      allSectionIds(s.sub_sections, collection);
                    }
                  });
                };
                
                allSectionIds(data.details);
              
                setTemplateData({
                  ...templateData,
                  template_details : data.details,
                  selected : collection,
                  collapsed : [],
                  frontMatter : data.frontMatter,
                  lines : data.lines,
                  name : templateName,
                  frontMatterOptions : frontMatterOptions,
                });     
              }          
            });
          }
          else {
            res.json().then((data) => {
        
              if (data.message) {
                alert(data.message);
              }
              else {
                alert('Your login has expired'); 
              }
          
              setTopData(initialState);          
            })      
          }
        })
        .catch((err) => {
          console.error(err);
          alert('error');
        });      
      }
      else {
        dataFetching = false;                     
        addSection();   
      }
    }
  }, []);  
  
  const addFrontMatter = (event) => {
    const name = event.target.name;
    if (templateData.frontMatter.includes(name)) {
      const updated = templateData.frontMatter.reduce((prev, curr) => {
        if (curr !== name) {
          prev.push(curr);
        }
        return prev;
      }, []);
      
      setTemplateData({ ...templateData, frontMatter: updated });
    }
    else {
      const updated = [ ...templateData.frontMatter, name ];
      setTemplateData({ ...templateData, frontMatter: updated });
    }
  };
  const gotoText = (e) => {
    e.preventDefault();

    let max = 0;
    const findNew = (sections) => {
      sections.map((s) => {
        if (s.id) {          
          const m = s.id.match(/newSection-(\d+)/);
          if (m) {
            if (parseInt(m[1]) > max) {
              max = parseInt(m[1]);
            }            
          }
          if (s.sub_sections) {
            findNew(s.sub_sections);
          }
        }
      });
    };
    findNew(templateData.template_details);
    setTemplateData({
      ...templateData,
      newSectionNum : max,      
    });

    setTopData({
      ...topData,
      page : 'templateText',
    });
  };

  return (
    <Container className="subPage" style={{"height":"1200"}}>
      <Row className="subPageRow">
        <h4 className="pageLevel2Head">
          Standard Pages
        </h4>
        <Row id="defaultSections">          
            <Col xs={{offset: 1, span: 4}} style={{"paddingRight":"6rem"}}>            
              <Form.Check
                  inline
                  label="Title Page"
                  name="titlePage"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("titlePage")}
              />
              <Form.Check
                  inline
                  label="Table of Contents"
                  name="tableOfContents"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("tableOfContents")}
              />
              <Form.Check
                  inline
                  label="Synopsis"
                  name="synopsis"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("synopsis")}
              />
            </Col>
            <Col xs={{offset: 1, span: 4}} style={{"paddingRight":"7rem"}}>            
              <Form.Check
                  inline
                  label="Amendments"
                  name="amendments"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("amendments")}
              />
              <Form.Check
                  inline
                  label="Abbreviations"
                  name="abbreviations"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("abbreviations")}
              />
              <Form.Check
                  inline
                  label="Schematic"
                  name="schematic"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("schematic")}
              />
            </Col>
            <Col xs={{offset: 1, span: 4}} style={{"paddingRight":"1rem"}}>            
              <Form.Check
                  inline
                  label="Signature Page"
                  name="signaturePages"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("signaturePages")}
              />
              <Form.Check
                  inline
                  label="Statement of Compliance"
                  name="complianceStatement"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("complianceStatement")}
              />
              <Form.Check
                  inline
                  label="Schedule of Events"
                  name="scheduleOfEvents"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("scheduleOfEvents")}
              />
            </Col>
        </Row>
      </Row>
      <Row className="subPageRow">
        <Row>
          <Col xs="8">
            <h4 className="pageLevel2Head" id="parentSectionsLabel">
              Parent Sections
            </h4>
          </Col>
          <Col >
            {
              templateData.selected.length ? 
                <div className="d-grid gap-2">
                  <Button 
                    variant="primary" 
                    className="intNavButton" 
                    id="sectionsNextButton"
                    onClick={gotoText}>
                      Next
                  </Button>
                </div>              
              : ""
            }
          </Col>
        </Row>
        <Row id="parentSections">
            <Sections />  
        </Row>
      </Row>
    </Container>
  );
}
        /*
      <Row style={{marginTop:30}}>
        <FrontMatterButtons />
      </Row>
      */

export default TemplateSetup;