import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Col, Spinner } from 'react-bootstrap';
import AsclepiaLib from "./AsclepiaLib";

function Trash() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="bi bi-trash3-fill trashButton" viewBox="0 0 16 16">
      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
    </svg>    
  );
}

function Copy() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
    </svg>
  );
}

function Documents() {
  const { topData, setTopData } = useTopDataStore();
  
  useEffect(() => {
    const companyDocuments = AsclepiaLib.deepCopy(topData.existing);
       
    companyDocuments.sort((a,b) => (a.documentName > b.documentName) ? 1 : -1);
    setTopData({
      ...topData,
      companyDocuments : companyDocuments,
    });
  }, [topData.existing]);

  const gotoDocument = (event) => {
    event.preventDefault();
            
    setTopData({
      ...topData,
      page : 'documentPageDisambiguate',
      document_id : event.target.dataset.id,
    });
  };

  if ("undefined" === typeof(topData.existing)) {  
    return (
      <li className="nav-item" key="waitingForDocument" style={{paddingLeft:"40px"}}>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />                        
      </li>
    );
  } 
  else {
    const deleteDoc = (e, id, name) => {
      e.preventDefault();
      if (window.confirm(`Permanently delete document ${name}?`)) {
        const existing = topData.existing.reduce((prev, curr) => {
          if (curr.documentId !== id) {
            prev.push(curr);
          }
          return prev;
        }, []);
        
        setTopData({
          ...topData,
          existing : existing,
        });
              
        fetch(topData.endpoint + '/document', {
          method : "DELETE",
          body   : JSON.stringify({
            email      : topData.userEmail,
            token      : topData.token,
            documentId : id,
            Key        : topData.AccessKeyId,
            Secret     : topData.SecretAccessKey,
            nonce      : topData.nonce,
            groupName  : topData.groupName,
            userId     : topData.userId,
          })
        })
        .then((res) => {
          if (200 !== res.status) {
            res.json().then((data) => {
      
              if (data.message) {
                alert(data.message);
              }
              else {
                alert('Your login has expired'); 
              }
        
              setTopData(initialState);          
            });
          }
        })
        .catch((err) => {
          console.error(err);
          alert('error');
        });
      }      
    };
    
    const cloneDoc = (e, id, name, version) => {
      e.preventDefault();
      
      setTopData({
        ...topData,
        cloning: id,
      });
      
      fetch(topData.endpoint + '/document', {
        method : "POST",
        body   : JSON.stringify({
          email      : topData.userEmail,
          token      : topData.token,
          documentId : id,
          version    : version,
          cloneName  : `Clone of ${name}`,
          Key        : topData.AccessKeyId,
          Secret     : topData.SecretAccessKey,
          nonce      : topData.nonce,
          groupName  : topData.groupName,
          userId     : topData.userId,
        })
      })
      .then((res) => {
        if (200 === res.status) {
          res.json().then((data) => {
            data.documentData.documentId = data.id;
            setTopData({
              ...topData,
              existing         : [ ...topData.existing, data.documentData ],
              companyDocuments : [ ...topData.companyDocuments, data.documentData ],
              cloning          : 0
            });
          });
        }
        else {
          res.json().then((data) => {
    
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
      
            setTopData(initialState);          
          });
        }
      })
    };
    
    return topData.companyDocuments.reduce((collection, documentData) => {
      if (documentData.documentId) {
        collection.push(
          <div className="docListItem" key={documentData.documentId}>
            <div className="docTextBlock">
              <a href="#id" data-id={documentData.documentId} onClick={gotoDocument}>
                {documentData.documentName || 'Unnamed Document'}
              </a> &nbsp;
              <span className="docListItemDetail">
                Based on {documentData.template_name} v. {documentData.template_version}
              </span>
            </div>
            <a href="/delete-doc" 
              onClick={(e) => deleteDoc(e, documentData.documentId, documentData.documentName)} 
              className="trashButtonList"
            >
              <Trash />          
            </a>
            { (topData.cloning && documentData.documentId === topData.cloning) ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />      
              :
              <a href="/clone-doc"
                onClick={(e) => cloneDoc(e, documentData.documentId, documentData.documentName, documentData.version)}
              >
                <Copy />  
              </a>
            }
          </div>
        );
      }
      return collection;
    }, []);
  }
}

function OutletSidebar() {
  return(
  <>
      <ul className="nav flex-column">
          <li className="nav-item intNav1" key="tempOutline">
              <span className="selected">
                   Protocols
              </span>
          </li>
      </ul>
  </>
  );
}

function DocumentList() {
  return (
        <Container className="mainPage">
            <Row>
                <h2 className="pageTitle">
                    Documents
                </h2>
            </Row>
            <Row> 
              <h4 className="pageLevel2Head">
                Protocols
              </h4>
              <Documents />    
            </Row>
        </Container>

    );
}

export default DocumentList;