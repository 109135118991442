import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

function Plus() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
    </svg>
  );
}

function File() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
    </svg>
  );
}

function Trash() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="bi bi-trash3-fill trashButton" viewBox="0 0 16 16">
      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
    </svg>    
  );
}

function TemplateList() {
  const { topData, setTopData }           = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  
  const gotoSetup = (event, id, version) => {
    event.preventDefault();
    
    setTemplateData({
        ...templateData,
        footer : true,
        template_id : id,
        template_version : version,
        draft_id : "",
        dirty : 0,
        loading : id,
      });

      if (templateData.template_id || id) {
        const url = topData.endpoint + "/defaults?email=" + topData.userEmail + '&userId=' + topData.userId + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
        fetch(url, {
          method  : "GET",
          headers : {
            Authorization : topData.token,
          }                  
        })
        .then((res) => {
          if (200 === res.status) {
            res.json().then((data) => {
              setTemplateData({
                ...templateData,
                lines    : data.lines,
                default_vars : data.variables,
                footer : true,
                template_id : id,
                template_version : version,
                draft_id : "",
                dirty : 0,
                loading  : false,
              });
  
              setTopData({
                ...topData,
                page : "templateSetup",
              });        
            });
          }
          else {
            res.json().then((data) => {
  
              if (data.message) {
                alert(data.message);
              }
              else {
                alert('Your login has expired'); 
              }
  
              setTopData(initialState);          
            })                    
          }      
        })
        .catch((error) => {
          console.error('Error', error.message);           
          alert({message: "Error getting data."});
        });                      
      }
    };
    
  const deleteTemplate = (e, id, name, version) => {
    e.preventDefault();
    
    if (window.confirm(`Permanently delete template ${name} and all derived draft templates and documents?`)) {
      const templates = templateData.templates.reduce((prev, curr) => {
        if (curr.id !== id) {
          prev.push(curr);
        }
        return prev;
      }, []);
      
      const drafts = templateData.drafts.reduce((prev, curr) => {
        if (curr.id !== id) {
          prev.push(curr);
        }
        return prev;
      }, []);
      
      setTemplateData({
        ...templateData,
        templates : templates,
        drafts    : drafts,
      });
            
      fetch(topData.endpoint + '/template', {
        method : "DELETE",
        body   : JSON.stringify({
          email            : topData.userEmail,
          token            : topData.token,
          template_id      : id,
          template_version : version,
          Key              : topData.AccessKeyId,
          Secret           : topData.SecretAccessKey,
          nonce            : topData.nonce,
          groupName        : topData.groupName,
          userId           : topData.userId,
        })
      })
      .then((res) => {
        if (200 !== res.status) {
          res.json().then((data) => {
    
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
      
            setTopData(initialState);          
          });
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });
    }          
  };
  
  if (templateData.templates.length) {
    templateData.templates.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    return templateData.templates.map((t) => {
      return(
        <div key={t.id} className="docListItem">
          <div className="docTextBlock">
            <a href="#id" onClick={(e) => gotoSetup(e, t.id, t.version)}>
              {t.name || 'Unnammed Template'} 
            </a>&nbsp;
          <span className="docListItemDetail">{t.description}</span>
          </div>
            <a href="/delete-template" 
              onClick={(e) => deleteTemplate(e, t.id, (t.name || 'Unnamed Template'), t.version)} 
              className="trashButtonList"
            >
              <Trash />            
            </a>
        </div>
      );   
    });    
  }
  else {
    return (
      <div className="welcomeText">
        Loading available templates. 
      </div>    
    );
  }
}

function DraftList() {
  const { topData, setTopData }           = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  
  const gotoSetup = (event, id, version, draft, name, description) => {
    event.preventDefault();
    setTemplateData({
      ...templateData,
      loading : draft,
    });
  
    const url = topData.endpoint + "/defaults?email=" + topData.userEmail + '&userId=' + topData.userId + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
    fetch(url, {
      method  : "GET",
      headers : {
        Authorization : topData.token,
      }                  
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          setTemplateData({
            ...templateData,
            lines        : data.lines,
            default_vars : data.variables,
            footer       : true,
            template_id  : id,
            template_version : version,
            draft_id : draft,
            dirty    : 0,
            loading  : false,
            name         : name, 
            newDraftName : name,
            description  : description,
            draftDescription : description,
          });

          setTopData({
            ...topData,
            page : "templateSetup",
          });        
        });
      }
      else {
        res.json().then((data) => {

          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }

          setTopData(initialState);          
        })                    
      }      
    })
    .catch((error) => {
      console.error('Error', error.message);           
      alert({message: "Error getting data."});
    });                      
  };

  const deleteTemplate = (e, template_id, name, version, id) => {
    e.preventDefault();

    if (window.confirm(`Permanently delete template draft ${name}?`)) {
      const drafts = templateData.drafts.reduce((prev, curr) => {
        if (curr.draft_id !== id) {
          prev.push(curr);
        }
        return prev;
      }, []);
      
      setTemplateData({
        ...templateData,
        drafts : drafts,
      });
            
      fetch(topData.endpoint + '/template', {
        method : "DELETE",
        body   : JSON.stringify({
          email            : topData.userEmail,
          token            : topData.token,
          draft_id         : id,
          template_id      : template_id,
          template_version : version,
          Key              : topData.AccessKeyId,
          Secret           : topData.SecretAccessKey,
          nonce            : topData.nonce,
          groupName        : topData.groupName,
          userId           : topData.userId,
        })
      })
      .then((res) => {
        if (200 !== res.status) {
          res.json().then((data) => {
    
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
      
            setTopData(initialState);          
          });
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });
    }          
  };

  templateData.drafts.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
  return templateData.drafts.map((d) => {
    const templateName = templateData.templates.reduce((prev, curr) => {
      if (d.id === curr.id) { return curr.name; }
      else { return prev; }
    }, ""); 
    return(
      <div key={d.id+'-'+d.draft_id+'.'+d.version} className="docListItem">
        <div className="docTextBlock">
          <a href="#id" onClick={(e) => gotoSetup(e, d.id, d.version, d.draft_id, d.name, d.description)}>
            {d.name || 'Unnamed Draft'} 
          </a>&nbsp;
        <span className="docListItemDetail">
        {(d.version == 0 ? 'Draft, original' : ('Draft, based on version ' + d.version + ' of ' + templateName))}
        </span>
        </div>
          <a href="/delete-template" 
            onClick={(e) => deleteTemplate(e, d.id, d.name, d.version, d.draft_id)}            className="trashButtonList"
          >
            <Trash />            
          </a>
      </div>
    );   
  });
}

function TemplatesList() {
  const { topData, setTopData }           = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();

  let dataFetching = false;
  useEffect(() => {
    if (!dataFetching && !templateData.templates.length) {
      dataFetching = true;
      // Terminal error:   Line 108:22:  Assignments to the 'dataFetching' variable from inside React Hook useEffect will be lost after each render. To preserve the value over time, store it in a useRef Hook and keep the mutable value in the '.current' property. Otherwise, you can move this variable directly inside useEffect  react-hooks/exhaustive-deps

      window.scrollTo(0, 0);   
      setTemplateData({
        ...templateData,
        footer : false,
      });

      const url = topData.endpoint + "/template?email=" + topData.userEmail + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
      fetch(url, {
        method : "GET",
        cache : "no-cache",
        headers : {
          Authorization : topData.token,
        }      
      })
      .then((res) => {
        if (200 === res.status) {
          res.json().then((data) => {
            setTemplateData({
              ...templateData,
              templates : data.templates,
              drafts    : data.drafts,
              liveID    : "",
            });
            dataFetching = false;
          })
        }
        else {
          res.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
          
            setTopData(initialState);          
          })      
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });      
    }
    else if (!dataFetching) {
        setTemplateData({
          ...templateData,
          template_details : [],
          sectionHtml      : [],
          selected         : [],
          template_version : 0,
          template_id      : "",
          newSectionNum    : 0,
          draft_id         : "",
          liveID           : "",
          liveType         : "",
          collapsed        : [],
          description      : "", 
          dirty            : 0,
          draftDescription : "",
          newDraftName     : "",
          // Deleted out duplicate ids
          loading2         : false,
          loading3         : false,
          lines            : undefined,
        });
    }
  }, []);
  
  const gotoSetup = (event) => {
  
    event.preventDefault();

    setTemplateData({
      ...templateData,
      loading : true,
    });
    
    if (!templateData.loading) {
      const url = topData.endpoint + "/defaults?email=" + topData.userEmail + '&userId=' + topData.userId + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
      fetch(url, {
        method  : "GET",
        headers : {
          Authorization : topData.token,
        }                  
      })
      .then((res) => {
        if (200 === res.status) {
          res.json().then((data) => {
            setTemplateData({
              ...templateData,
              footer   : false,
              draft_id : "",
              dirty    : 0,
              template_id : "",
              template_version : 0,
              lines    : data.lines,
              default_vars : data.variables,
              loading  : false,
            });

            setTopData({
              ...topData,
              page : "templateSetup",
            });        
          });
        }
        else {
          res.json().then((data) => {

            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }

           setTopData(initialState);          
          })                    
        }      
      })
      .catch((error) => {
        console.error('Error', error.message);           
        alert({message: "Error getting data."});
      });                      
    }  
  };

  return (
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">Templates</h2>
      </Row>
      <Row>
        {
          templateData.templates ? 
            <>
              <Row key="newTemplate">
                <div className="docListItem" id="newTemplate">
                  <a href="#id" onClick={gotoSetup}>
                    <Plus />Create New Template
                  </a>
                </div>
              </Row>
              <Row>   
                <Col xs={10}>
                  <h4 className="pageLevel2Head">Drafts</h4>
                  <DraftList />
                </Col>        
                <Col xs={10}>
                <h4 className="pageLevel2Head proto">Protocols</h4>
                  <TemplateList />
                </Col>    
              </Row> 
            </>
          : <><Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            /><br/><br />
              <p className="loadWarning">
                Template list is loading from the database. 
                If this takes longer than anticipated, contact the database administrator.
              </p>
            </>                
        }
      </Row>
    </Container>
  );
}

export default TemplatesList;


/*

              <Row className="pageSubtitle">   
                <h3 className="pageLevel1Head">Protocols</h3>  
              </Row>

              */