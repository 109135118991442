import { createContext, useState, useContext } from "react";

const SynopsisDataStore = createContext();
export const useSynopsisDataStore = () => useContext(SynopsisDataStore);

export const initialState = {
  sections       : [],
  sectionOptions : [],
  active         : {html : "", name: "", id: ""},
  thisSection    : {html : "", name: "", id: ""},
  synSections    : {},
};  

export default function SynopsisDataStoreProvider({children}) {
  const [synopsisData, setSynopsisData] = useState(initialState);
  
  return (
    <SynopsisDataStore.Provider value={{ synopsisData, setSynopsisData }}>
      {children}
    </SynopsisDataStore.Provider>
  );
}