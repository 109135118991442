import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

function Share() {
  const { topData, setTopData } = useTopDataStore();

  useEffect(() => {
    const url = topData.endpoint + "/document?email=" + topData.userEmail + '&userId=' + topData.userId + "&documentId=" + topData.shareId  + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
    fetch(url, {
      method : "GET",
      cache : "no-cache",
      headers : {
        Authorization : topData.token,
      }      
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          setTopData({ ...topData, documentData : data.document });
        })
      }
      else {
        res.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }
          
          setTopData(initialState);          
        })      
      }
    })
    .catch((err) => {
      console.log(err);
      alert("error retrieving data");
    });
  }, []);
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setTopData({ ...topData, [key]: val });
  };    
  
  const done = () => {
    setTopData({ ...topData, page: "existing", shareId: "", documentData : {}, sending: false });
  };
  
  const invite = (event) => {
    setTopData({ ...topData, sending: true });
    
    fetch(topData.endpoint + '/permission', {
      method : "POST",
      body : JSON.stringify({
        email       : topData.userEmail,
        token       : topData.token,
        document_id : topData.shareId, 
        invitee     : topData.shareWith,
        Key         : topData.AccessKeyId,
        Secret      : topData.SecretAccessKey,
        nonce       : topData.nonce,
        groupName   : topData.groupName,
      })
    })
    .then((res) => {
      if (401 === res.status) { 
        res.json().then((data) => {
      
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Login Expired'); 
          }
        
          setTopData(initialState);          
        })
      }
      else if (200 === res.status) {
        alert("invite sent");
        done();
      }
      else {
        alert("Error. Check the email and try again or contact tech support");
        setTopData({ ...topData, sending: false });
      }
    })
    .catch((err) => {
      setTopData({ ...topData, sending : false });
      console.log(err);
    });
    
  };
  

  return (
    <Container className="p-3">
      <Row style={{"marginBottom":"15px"}}>
        <Col>
          Please review the document information below. If this is the document for which you 
          want to share access, enter the email address to which an invite should be 
          delivered and press the "Invite" button. When you are done, press the "Done" button.
        </Col>
      </Row>
      <Row style={{"marginBottom":"15px"}}>
        <Col sm={12} md={6} style={{"marginBottom":"5px"}}>
          <Form.Control type="email" onChange={formAction} name="shareWith" value={topData.shareWith} placeholder="Email of person to share with" />
        </Col>
        <Col sm={12} md={3} style={{"marginBottom":"5px"}}>
          <Button onClick={invite} variant="success">
            {topData.sending && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!topData.sending && "Invite"}
          </Button>  
        </Col>
        <Col sm={12} md={3} style={{"marginBottom":"15px"}}>
          <Button onClick={done} variant="secondary">Done</Button>
        </Col>
      </Row>
      <table className="table">
        <tbody>
          <tr>
            <th>Sponsor</th>
            <td>{topData.documentData.sponsor}</td>
          </tr>
          <tr>
            <th>Study Number</th>
            <td>{topData.documentData.studynumber}</td>
          </tr>
          <tr>
            <th>Trial Name</th>
            <td>{topData.documentData.trialname}</td>
          </tr>
          <tr>
            <th>Title</th>
            <td>{topData.documentData.trialtitle}</td>
          </tr>
          <tr>
            <th>Registration Number</th>
            <td>{topData.documentData.trialnumber}</td>
          </tr>
          <tr>
            <th>Phase Number</th>
            <td>{topData.documentData.trialphase}</td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
}

export default Share;