import './Login.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useEffect } from "react";

function Offline() {

  const { topData, setTopData } = useTopDataStore();

  const checkStatus = () => {
    if (topData.AccessKeyId) {
      const url = topData.endpoint + "/status?email=" + topData.userEmail + '&userId=' + topData.userId + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
      fetch(url, {
        method : "GET",
        cache : "no-cache",
        headers : {
          Authorization : topData.token,
        }      
      })
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {

            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }

            setTopData(initialState);          
          })
        }
        else if (200 === res.status) {
          res.json().then((data) => {
            if ("offline" !== data.status) {
              if ("offline" === topData.page) {
                setTopData({
                  ...topData,
                  page        : topData.holdingPage,
                });      
              }
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });      
    } 
  };

  let dupCheck = true
  useEffect(() => {
    if (dupCheck) {
      dupCheck = false;
  
      let interval = null;
      interval = setInterval(() => {
        if (topData.AccessKeyId) {
          const url = topData.endpoint + "/status?email=" + topData.userEmail + '&userId=' + topData.userId + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
          fetch(url, {
            method : "GET",
            cache : "no-cache",
            headers : {
              Authorization : topData.token,
            }      
          })
          .then((res) => {
            if (401 === res.status) { 
              res.json().then((data) => {

                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }

                clearInterval(interval); 
                setTopData(initialState);          
              })
            }
            else if (200 === res.status) {
              res.json().then((data) => {
                if ("offline" !== data.status) {
                  if ("offline" === topData.page) {
                    clearInterval(interval); 
                    setTopData({
                      ...topData,
                      page : 'welcome',
                    });      
                  }
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });      
        } 
      }, 120000);
    
      return () => {
        setTimeout(() => {
          clearInterval(interval);     
        }, 1800000);
      }    
    }
  }, []);  
  
  return (
    <Container fluid>
      <Row>
        <Col xs={6} className="leftSide">
          <img src="/img/white.png" style={{width: "60%", height: "auto"}} alt="logo" />
        </Col>
        <Col xs={6} className="login-container" style={{backgroundColor:"#ffffff"}}>
          <Container className="p-3">
            <Row> 
              <Col>
                <h1 className="h3 mb-3 fw-normal">Asclepia Kinetika</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Files and databases are being updated&hellip; we'll be back momentarily.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <img src="/img/offline.gif" style={{maxWidth:"100%",height:"auto"}} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>   
  );
}

export default Offline;