import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';

function Profile() {

  const { topData, setTopData } = useTopDataStore();

  const saveProfile = (event) => {
    event.preventDefault();
    
    if (!topData.userName.match(/\w/)) {
      setTopData({ ...topData, userNameClass: 'is-invalid', userEmailClass: "", userPasswordClass: ""});
      return false;
    }
    
    if (!topData.userEmail.match(/\w@\w/)) {
      setTopData({ ...topData, userNameClass: "", userEmailClass: 'is-invalid', userPasswordClass: ""});
      return false;
    }
    
    if (topData.newPassword && !topData.newPassword.match(/\S\S\S\S\S\S\S\S/)) {
      setTopData({ ...topData, userNameClass: "", userEmailClass: "", userPasswordClass: 'is-invalid'});
      alert("Please choose a stronger password");
      return false;
    }

    if (topData.newPassword && topData.newPassword !== topData.confirmPassword) {
      setTopData({ ...topData, userNameClass: "", userEmailClass: "", userPasswordClass: 'is-invalid'});
      alert("New passwords do not match");
      return false;
    }

    if (topData.newPassword && !topData.userPassword.match(/\S\S\S\S\S\S/)) {
      setTopData({ ...topData, userNameClass: "", userEmailClass: ""});
      alert("To change your password, you must submit your current password");
      return false;
    }
    
    setTopData({ ...topData, loading: true });
    
    fetch(topData.endpoint + '/profile', {
      method : "PUT",
      body : JSON.stringify({
        token     : topData.token,
        userId    : topData.userId,
        email     : topData.userEmail,
        newEmail  : topData.newEmail,
        name      : topData.userName,
        password  : topData.userPassword,
        newPass   : topData.newPassword,
        Key       : topData.AccessKeyId,
        Secret    : topData.SecretAccessKey,
        nonce     : topData.nonce,
        groupName : topData.groupName,
      })
    })
    .then((res) => {
      if (401 === res.status) {
        alert("Your login has expired");

        setTopData(initialState);                  
      }
      else if (200 === res.status) {
        res.json().then((data) => {
          setTopData({
            ...topData,
            userPassword     : "_updated_",
            token            : data.token,
            loading          : false,
            page             : "welcome",
            AccessKeyId      : data.AccessKeyId,
            SecretAccessKey  : data.SecretAccessKey,
            nonce            : data.nonce,
            userEmail        : data.email,
          });        
        })
        .catch((err) => console.error(err));
      }      
    })
    .catch((err) => {
      console.error(err);
      setTopData({ ...topData, loading: false });
    });
  };

  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setTopData({ ...topData, [key]: val });
  };  

  return (
    <Container className="mainPage">
      <div className="d-flex justify-content-between">
        <h2 className="pageTitle">My Profile</h2>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <button type="button" className="btn btn-sm btn-outline-success" onClick={saveProfile}>
              {
                topData.loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />                
                ) : 'Save Changes'
              }
            </button>
          </div>
        </div>
      </div>
      <>
        <div className="d-flex" style={{"marginBottom":"10px","marginTop":"2rem"}}>
          <div className="col-2">
            My Name: 
          </div>
          <div className="col-6">
            <input type="text" className={"form-control " + topData.userNameClass} 
            name="userName" value={topData.userName} onChange={formAction}
            placeholder="Your name as it will appear to other users and on generated documents" />
          </div>
        </div>
        <div className="d-flex" style={{"marginBottom":"10px","marginTop":"10px"}}>
          <div className="col-2">
            My Email: 
          </div>
          <div className="col-6">
            <input type="text" className={"form-control " + topData.userEmailClass} 
            name="newEmail" value={topData.newEmail} onChange={formAction}
            placeholder="Email used for logging in and receiving confirmation codes. Must be a unique deliverable email address." />
          </div>
        </div>
        <div  className="d-flex" style={{"marginBottom":"10px","marginTop":"10px"}}>
          <div className="col-2">
            Current Password: 
          </div>
          <div className="col-6">
            <input type="text" className={"form-control"} autoComplete="current-password"
            name="userPassword" value={topData.userPassword} onChange={formAction}
            placeholder="************" />
          </div>
        </div>      
        <div  className="d-flex" style={{"marginBottom":"10px","marginTop":"10px"}}>
          <div className="col-2">
            New Password:<br />
            <em>(optional)</em>
          </div>
          <div className="col-6">
            <input type="text" className={"form-control " + topData.userPasswordClass} 
            name="newPassword" value={topData.newPassword} onChange={formAction}
            placeholder="************" autoComplete="new-password" />
          </div>
        </div>      
        <div  className="d-flex" style={{"marginBottom":"10px","marginTop":"10px"}}>
          <div className="col-2">
            Confirm New Password:<br />
            <em>(optional)</em>
          </div>
          <div className="col-6">
            <input type="text" className={"form-control " + topData.userPasswordClass} 
            name="confirmPassword" value={topData.confirmPassword} onChange={formAction}
            placeholder="************" autoComplete="new-password" />
          </div>
        </div>      
      </>      
    </Container>
  );
}

export default Profile;