import './App.css';
import { useEffect, useRef } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

function Left() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#41464b" className="bi bi-arrow-left-square-fill" viewBox="0 0 16 16">
      <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
    </svg>  
  );
}

function Right() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#41464b" className="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
      <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
    </svg>  
  );
}

function Down() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-square-fill" viewBox="0 0 16 16">
      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5a.5.5 0 0 1 1 0z"/>
    </svg>
  );
}

function Up() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-square-fill" viewBox="0 0 16 16">
      <path d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z"/>
    </svg>  
  );
}

function AlignStart() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-align-start" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M1.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z"/>
      <path d="M3 7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7z"/>
    </svg>
  );
}

function AlignCenter() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-align-center" viewBox="0 0 16 16">
      <path d="M8 1a.5.5 0 0 1 .5.5V6h-1V1.5A.5.5 0 0 1 8 1zm0 14a.5.5 0 0 1-.5-.5V10h1v4.5a.5.5 0 0 1-.5.5zM2 7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7z"/>
    </svg>  
  );
}

function AlignEnd() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-align-end" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M14.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z"/>
      <path d="M13 7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7z"/>
    </svg>  
  );
}

function Eye() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
    </svg>  
  );
}

function EyeSlash() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
    </svg>    
  );
}

function Lines() {
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { topData, setTopData }           = useTopDataStore();
  
  const moveDown = (line_id, lineList, rev = false) => {
    lineList = lineList || templateData.lines;
    let pending = "";
    const reordered = lineList.reduce((prev, curr) => {
      if (curr.id === line_id) {
        pending = curr;
      }
      else {
        prev.push(curr);
        if (pending) {
          prev.push(pending);
          pending = "";
        }
      }
      
      return prev;
    }, []);
    
    if (rev) {
      reordered.reverse();
    }
    
    setTemplateData({
      ...templateData,
      lines : reordered,
    });
  };

  const deepCopy = (data) => {
    return JSON.parse(JSON.stringify(data));
  };
  
  const moveUp = (line_id) => {
    const lineList = deepCopy(templateData.lines);
    lineList.reverse();
    
    moveDown(line_id, lineList, true);
  };
  
  const setAlign = (line_id, alignment) => {
    const updated = templateData.lines.map((l) => {
      if (line_id === l.id) {
        l.align = alignment;
      }
      return l;
    });
        
    setTemplateData({
      ...templateData,
      lines : updated,
      linesTouched : { ...templateData.linesTouched, [line_id] : 'align' },
    });
  };

  const setLabelVisibility = (line_id, hide) => {
    const updated = templateData.lines.map((l) => {
      if (line_id === l.id) {
        l.hide = hide;
      }
      return l;
    });
 
     setTemplateData({
      ...templateData,
      lines : updated,
      linesTouched : { ...templateData.linesTouched, [line_id] : 'hidden' },
    });
 };
  
  return templateData.lines.map((l) => {
    l.align = l.align || "start";
    l.hide  = l.hide || false;
    return (
      <Row key={l.id} className="mb-3">
        <Col xs={4}>
          { (l.id === templateData.lines[0].id) ? "" : 
          <Button variant="outline-primary" onClick={() => moveUp(l.id)} style={{marginRight:5}}>
            <Up />
          </Button>
          }
          
          {
            (l.id === templateData.lines[(templateData.lines.length - 1)].id) ? "" :
          <Button variant="outline-primary" onClick={() => moveDown(l.id)} style={{marginRight:5}}>
            <Down />
          </Button>
          }
          
          <ButtonGroup className="mb-2" style={{marginRight:5}}>          
            <ToggleButton
             type="radio"
              variant="outline-secondary"
              checked={'start' === l.align}
              value="start"
              onClick={() => setAlign(l.id, 'start')}
            > <AlignStart /> </ToggleButton>

            <ToggleButton
             type="radio"
              variant="outline-secondary"
              checked={'center' === l.align}
              value="center"
              onClick={() => setAlign(l.id, 'center')}
            > <AlignCenter /> </ToggleButton>

            <ToggleButton
             type="radio"
              variant="outline-secondary"
              checked={'end' === l.align}
              value="end"
              onClick={() => setAlign(l.id, 'end')}
            > <AlignEnd /> </ToggleButton>
            
          </ButtonGroup>
          
          { l.hide ?
            <Button variant="outline-primary" onClick={() => setLabelVisibility(l.id, false)} style={{marginRight:5}}>
              <EyeSlash />
            </Button>
            :
            <Button variant="outline-primary" onClick={() => setLabelVisibility(l.id, true)} style={{marginRight:5}}>
              <Eye />
            </Button>
          }
          
        </Col>
        <Col xs={8} align={l.align}>
          <span className={l.hide ? "text-muted" : "font-weight-bold"}>{l.label}:</span> <div dangerouslySetInnerHTML={{__html: l.html}} />
        </Col>
      </Row>
    );
  });
}


function TemplateTitleLayout() {
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { topData, setTopData }           = useTopDataStore();
  
  useEffect(() => {
    setTemplateData({
      ...templateData,
      linesTouched : {},
      saving       : "",
    }); 
  }, []);
  
  const saveUpdates = (next) => {
    const updated = Object.keys(templateData.linesTouched);
    if (updated.length) {
      const changed = templateData.lines.reduce((prev, curr) => {
        if (updated.includes(curr.id)) {
          prev.push(curr);
        }
        return prev;
      }, []); 
    
      fetch(topData.endpoint + "/lines", {
        method : "PUT",
        body   : JSON.stringify({
          email       : topData.userEmail,
          token       : topData.token,
          userId      : topData.userId,
          updated     : changed,
          template_id : templateData.template_id,
          version     : templateData.template_version,
          draft_id    : templateData.draft_id,
          Key         : topData.AccessKeyId,
          Secret      : topData.SecretAccessKey,
          nonce       : topData.nonce,
          groupName   : topData.groupName,
          selected    : templateData.selected,
          frontMatter : templateData.frontMatter,
        })
      })
      .then((resp) => {
        if (200 === resp.status) {
          resp.json().then((data) => {
            if (!templateData.draft_id) {
              setTemplateData({
                ...templateData,
                draft_id : data.draft.draft_id,
                drafts   : [ ...templateData.drafts, data.draft, ],
                lines    : data.lines,
              });
            }
            else {
              setTemplateData({
                ...templateData,
                lines : data.lines,
              });
            }
            
            next();
          })
        }
      })
    }
    else { next(); }
  };  
  
  const goBack = (e) => {
    e.preventDefault();
    setTemplateData({
      ...templateData,
      saving : "goBack",
    });
    
    saveUpdates(() => {
      setTopData({
        ...topData,
        page: 'templateTitlePage',
      });    
    });    
  };
  
  const saveChanges = (e) => {
    e.preventDefault();
    setTemplateData({
      ...templateData,
      saving : "saveChanges",
    });
    
    saveUpdates(() => {
      setTopData({
        ...topData,
        page : 'templateSaveChoices',
      });        
    });
  };
  
  const gotoTitleEdit = (e) => {
    e.preventDefault();
    setTemplateData({
      ...templateData,
      saving : "gotoTitleEdit",
    });
    
    saveUpdates(() => {
      setTopData({
        ...topData,
        page: 'templateTitleEdit',
      });    
    });    
  };
  

  return (
    <Container className="p-3" id="contentContainer">
      <Row className="mb-3">
        <Col sm={12} style={{"marginBottom":"5px"}}>
          <h1 className="h2">Template {templateData.name} Title Page: Edit approximate layout.</h1>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <strong>Meaning of buttons:</strong>Move item up or down on page; Align left/center/right; Show/hide label for item.
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={goBack}
            >
              { (templateData.saving && 'goBack' === templateData.saving) ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />        
                : <>BACK: FORM</>              
              }              
            </Button>
          </div>              
        </Col>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={saveChanges}
            >
              { (templateData.saving && 'saveChanges' === templateData.saving) ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />    
                : <>SAVE</>              
              }              
            </Button>
          </div>              
        </Col>
        <Col xs={3}>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{backgroundColor:"#F07167",borderColor:"#F07167",marginBottom:10}} 
              onClick={gotoTitleEdit}
            >
              { (templateData.saving && 'gotoTitleEdit' === templateData.saving) ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />    
                : <>EDIT</>              
              }              
            </Button>
          </div>              
        </Col>
      </Row>

      {
        templateData.warning ? 
        <Row className="mb-3">
          <Col>
            <Alert key={'danger'} variant={'danger'}>
              {templateData.warning}
            </Alert>
          </Col>
        </Row>
        : ""            
      }
      
      <Lines />
          
    </Container>
  );
}

export default TemplateTitleLayout;