import { createContext, useState, useContext } from "react";

const TemplateDataStore = createContext();
export const useTemplateDataStore = () => useContext(TemplateDataStore);

export default function TemplateDataStoreProvider({children}) {
  const [templateData, setTemplateData] = useState({
    templates        : [], 
    newSectionNum    : 0, 
    selected         : [], 
    template_details : [],
    draft_id         : "",
    drafts           : [],
  });
  
  return (
    <TemplateDataStore.Provider value={{ templateData, setTemplateData }}>
      {children}
    </TemplateDataStore.Provider>
  );  
}