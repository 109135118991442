import { createContext, useState, useContext } from "react";

const VariableDataStore = createContext();
export const useVariableDataStore = () => useContext(VariableDataStore);

export default function VariableDataStoreProvider({children}) {
  const [variableData, setVariableData] = useState({
    howDelete : {}, 
    other     : [],
    variables : {},
  });
  
  return (
    <VariableDataStore.Provider value={{ variableData, setVariableData }}>
      {children}
    </VariableDataStore.Provider>
  );
}