import './App.css';
import { useEffect } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useDocumentDataStore } from "./DocumentDataStoreProvider";
import Spinner from 'react-bootstrap/Spinner';


// DEVIN: Do we need this doc, or could all this function be moved into the DocumentOutletId???

function LoadingProj() {
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { topData, setTopData }         = useTopDataStore();

  let dataFetching = false;
  useEffect(() => {
    const document = topData.existing.reduce((prev, curr) => {
      if (curr.documentId === topData.document_id) {
        return curr;
      }
      else { return prev; }
    }, {});
    
    setDocumentData({
      ...documentData,
      ...document,
    });
    
    /*
      There used to be some code here which would tell if the users had been to the 
      edit full document sections step yet, so we could tell if we should send them to the
      data forms or directly to the edit page. That should be redone at some point. It 
      was disconnected because the data it was relying on was being redone. 
    */
    const startAtPage = (1 === 2) ? 'edit' : 'studyInformation';
    setTopData({
      ...topData,
      sections : [],
      page     : startAtPage,
    });
  }, [topData.document_id]);
  
  return (
    <Spinner
      as="span"
      animation="border"
      size="lg"
      role="status"
      aria-hidden="true"
    />          
  );
}

export default LoadingProj;