import './App.css';
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useDocumentDataStore } from "./DocumentDataStoreProvider";
import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import { compareDesc, parse } from 'date-fns';
import formatRFC7231 from 'date-fns/formatRFC7231'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Variables() {
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { topData, setTopData }           = useTopDataStore();

  let formPage = '';
  
  switch(topData.page) {
    case 'studyInformation': 
      formPage = 'Study Information';
      break
    case 'objectives':
      formPage = 'Objectives';
      break
    case 'studyDesign':
      formPage = 'Study Design';
      break
    case 'patientPopulation':
      formPage = 'Patient Population';
      break
    case '_variables':
      formPage = 'Variables';
      break
    default:
      formPage = 'Study Information';      
  }

  let loading = false;
  useEffect(() => {
    if (!loading && !Object.keys(documentData.variableData).length) {
      loading = true;
      const template_id      = documentData.template_id || topData.template_id;
      const template_version = documentData.template_version || topData.template_version;
      const url = topData.endpoint + "/variable?email=" + topData.userEmail + "&template_id=" + template_id + "&template_version=" + template_version + '&Key=' + topData.AccessKeyId + '&Secret=' + topData.SecretAccessKey + '&nonce=' + topData.nonce + '&groupName=' + topData.groupName;
      fetch(url, {
        method : "GET",
        cache : "no-cache",
        headers : {
          Authorization : topData.token,
        }      
      })
      .then((res) => {
        if (200 === res.status) {
          res.json().then((returned) => {           
            const values = {};
            Object.keys(returned.data).map((id) => {
              returned.data[id].variables.map((v) => {
                if (returned.data[id].multiple) {
                  values[v.id] = [""];
                }
                else {
                  values[v.id] = "";
                }
              });
            });

            setDocumentData({
              ...documentData,
              sections         : topData.sections,
              lines            : topData.lines,
              variableData     : returned.data,
              variableValues   : values,
              variablesFetched : true,
            });                  
          });
        }
        else {
          res.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
          
            setTopData(initialState);          
          });      
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });   
    }
  }, []);
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    if (key.match(/\./)) {
      const k      = key.split('.');
      const values = documentData.variableValues[k[0]];
      values[(parseInt(k[1]) - 1)] = val;
      setDocumentData({ 
        ...documentData, 
        variableValues : {
          ...documentData.variableValues,
          [k[0]] : values,
        }
      });
    }
    else {
      setDocumentData({ 
        ...documentData, 
        variableValues : {
          ...documentData.variableValues,
          [key]: val,          
        }
      });
    }
  };  
  
  const variants = (vset, i) => {
    if (vset.multiple) {
      return vset.variables.map((variant) => {
        return (
          <Col 
              xs={(1 === vset.variables.length) ? 12 : 4}
              className="dataCol"
              key={`${variant.id}.${i}`}
          >
            <Form.Text className="dataText">
              {variant.variant}
            </Form.Text>
            {
              variant.select ? 
                <Form.Select 
                  name={`${variant.id}.${i}`} 
                  value={documentData.variableValues[variant.id][(i-1)]} 
                  onChange={formAction}
                  className="dataDrop"
                >
                  {variant.options.map((o) => 
                    <option value={o} key={o.replace(/\s+/g, '-')+'.'+i}>{o}</option>)
                  }
                </Form.Select>
              : <Form.Control 
                  type="text" 
                  name={`${variant.id}.${i}`}
                  className="dataLiveType" 
                  value={documentData.variableValues[variant.id][(i-1)]} 
                  onChange={formAction} 
                />
            }
          
            {
              variant.example ?
              <Form.Text 
                className="dataExample" 
                key={`example-${variant.id}.${i}`}>
                  Example: {variant.example}
                </Form.Text>
              : <></>
            }
          </Col>      
        );
      });    
    }
    else {
      return vset.variables.map((variant) => {
        return (
          <Col 
            xs={(1 === vset.variables.length) ? 12 : 4} 
            key={variant.id}
            className="dataCol"
          >
            <Form.Text className="dataText">
              {variant.variant}
            </Form.Text>
            {
              variant.select ? 
                <Form.Select 
                  name={variant.id} 
                  value={documentData.variableValues[variant.id]} 
                  className="dataDrop dataLiveType"
                  onChange={formAction}
                >
                  {variant.options.map((o) => 
                    <option value={o} key={o.replace(/\s+/g, '-')}>
                      {o}
                    </option>)}
                </Form.Select>
              : <Form.Control 
                  type="text" 
                  name={variant.id} 
                  value={documentData.variableValues[variant.id]} 
                  onChange={formAction} 
                  className="dataLiveType"
                />
            }
          
            {
              variant.example ?
              <Form.Text 
                className="dataExample" 
                key={`example-${variant.id}`}
              >
                Example: {variant.example}
              </Form.Text>
              : <></>
            }
          </Col>      
        );
      });    
    }  
  };
  
  const addInstance = (id) => {
    const additions = {};
    documentData.variableData[id].variables.map((v) => {
      additions[v.id] = [ ...documentData.variableValues[v.id], "" ];      
    });
    
    setDocumentData({
      ...documentData,
      variableValues : { ...documentData.variableValues, ...additions },
    });
  };
  
  if (documentData.variablesFetched || Object.keys(documentData.variableData).length > 1) {
    return Object.keys(documentData.variableData).reduce((prev, curr) => {
      if (documentData.variableData[curr].form_section === formPage) {      
      
        if (topData.deadVars[curr]) { /* don't include deleted variables in form */ }
        else if (documentData.variableData[curr].multiple) {
          const defaultVariant = documentData.variableData[curr].variables[0];
          let count = 0;
          const instances = documentData.variableValues[defaultVariant.id].map((v) => {
            count++;
            return (
              <Form.Group 
                className="dataGroup" 
                key={`form-group-${curr}-${count}`}
              >
                <Form.Label className="dataLabel">
                  {documentData.variableData[curr].name} #{count}
                </Form.Label>
                <Form.Text className="dataGuidance">
                    {documentData.variableData[curr].description}
                  </Form.Text>
                <Row className="dataVarRow">
                  {variants(documentData.variableData[curr], count)}
                </Row>
                {
                  (count === documentData.variableValues[defaultVariant.id].length) ? 
                    <Button 
                      variant="primary" 
                      style={{backgroundColor:"#F07167",borderColor:"#F07167",marginTop:10}} 
                      onClick={() => addInstance(curr)}
                      className="dataAddInstance"
                    > 
                      ADD {documentData.variableData[curr].name.toUpperCase()} 
                    </Button>              
                  : ""
                }
              </Form.Group>        
            );
          });
          prev = prev.concat(instances);
        }
        else {
          prev.push (
            <Form.Group 
              className="dataGroup" 
              key={`form-group-${curr}`}
            >
              <Form.Label className="dataLabel">
                {documentData.variableData[curr].name}
              </Form.Label>
              <Form.Text 
                  className="dataGuidance">
                {documentData.variableData[curr].description}
                </Form.Text>
              <Row className="dataVarRow">
                {variants(documentData.variableData[curr], 0)}
              </Row>
              {
                documentData.variableData[curr].multiple ? 
                  <Button 
                    variant="primary" 
                    className="dataAddInstance" 
                    onClick={(curr) => addInstance}
                  > 
                    ADD {documentData.variableData[curr].name.toUpperCase()} 
                  </Button>              
                : ""
              }
            </Form.Group>        
          );        
        }      
      }
      return prev;
    }, []);
  }
  else {
    return (
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />    
    );
  }
}


function DataForm() {
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { topData, setTopData }         = useTopDataStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const jump = (e) => {
    e.preventDefault();
    setDocumentData({
      ...documentData,
      jumpto : e.target.dataset.jumpto,
    });
    saveExit(e.target.dataset.jumpto);
  };
  
  const nextForm = (e) => {
    e.preventDefault();
    setDocumentData({
      ...documentData,
      loading : true,
    });
    const pages = ['studyInformation', 'objectives', 'studyDesign', 'patientPopulation', '_variables', "edit"];
    const next  = pages.indexOf(topData.page) + 1;
    saveExit(pages[next]);
  };
  
  let putDocument = false;
  const saveExit = (next) => {
    if ("welcome" === next) {
      setDocumentData({
        ...documentData,
        saving : true,
      });    
    }
    
    if (!putDocument) {
      putDocument = true;
      fetch(topData.endpoint + '/document', {
        method : "PUT",
        body   : JSON.stringify({
          email            : topData.userEmail,
          token            : topData.token,
          userId           : topData.userId,
          userName         : topData.userName,
          id               : documentData.documentId,
          version          : documentData.version,
          template_id      : documentData.template_id || topData.template_id,
          template_version : documentData.template_version || topData.template_version,
          variableValues   : documentData.variableValues,
          documentName     : documentData.documentName,
          sections         : documentData.sections,
          Key              : topData.AccessKeyId,
          Secret           : topData.SecretAccessKey,
          nonce            : topData.nonce,
          groupName        : topData.groupName,
        })
      })
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has timed out'); 
            }
            setTopData(initialState);          
          })
        }
        else if (200 === res.status) {
          res.json().then((data) => {
        
            const newDocument = ("" === documentData.documentId);
            if (newDocument) {
              const newDocList = [
                ...topData.existing, 
                {
                  ...documentData, 
                  documentId         : data.documentId, 
                  version            : data.version,
                  'template_id'      : data.template_id,
                  'template_version' : data.template_version,
                  'template_name'    : data.template_name,
                }
              ];

              setTopData({
                ...topData, 
                page             : next,
                existing         : newDocList,
                companyDocuments : newDocList,
              });   
            }
            else {
              const existing = topData.existing.map((e) => {
                if (e.documentId === data.documentId) {
                  e = { 
                    ...documentData, 
                    version: data.version, 
                    updated_at : Date.now(),
                    variableData : documentData.variableData,
                    variableValues : documentData.variableValues,                    
                  }
                }
                return e;
              });
              setTopData({
                ...topData, 
                page : next,
                existing : existing,
                companyDocuments : existing,
              });                      
            }
            
            setDocumentData({ 
              ...documentData, 
              saving    : false,
              loading   : false,
              jumpto    : "",
              documentId : data.documentId,
              version   : data.version,
              'template_id'      : data.template_id,
              'template_version' : data.template_version,
              'template_name'    : data.template_name
            });
            
            putDocument = false;
          });      
        }   
      })
      .catch((err) => {
        setDocumentData({ ...documentData, loading : false, saving : false, jumpto : "", });
        console.log(err);
        alert("error 379-DataForm");
        putDocument = false;
      });
    
    }
  };
  
  return (
    <Container className="subPage">
      <Row className="mainPageRow">
        <div className="topButtonGroup align-items-end">
          <Button className="intNavButton dataBtn" 
            id="dataTopButton"
            onClick={nextForm} 
          >
            {documentData.loading && (
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            {!documentData.loading && "Next"}
          </Button> 
          &nbsp; &nbsp;&nbsp;    
          <Button className="intNavButton dataBtn" 
            id="dataTopButton"
            onClick={nextForm} 
          >
            {documentData.saving && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            {!documentData.saving && "Save"}
          </Button>   
        </div>
      </Row> 
      <Form>
        {
          ('studyInformation' === topData.page) ?
          <Form.Group className="dataGroup" key="documentName">
            <Form.Label className="dataLabel">
              Document Name
            </Form.Label>
            <Form.Text className="dataGuidance">
              Internal Use: Name for tracking document within Kinetika system
            </Form.Text>
            <Form.Control 
              type="text" 
              value={documentData.documentName} 
              onChange={(e) => setDocumentData({...documentData, documentName: e.target.value})} 
              className="dataLiveType"
            />
          </Form.Group>
          : <></>
        }
        <Variables />
      </Form>
    </Container>
  );
}

export default DataForm;